declare let browser: any;

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_INSIGHTS, AUTH_CONFIG_ID, FORBIDDEN_PATH, LOGOUT_PATH, PASSTHROUGH_URLS, UNAUTHORIZED_PATH } from '@app/constants';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigurationService, LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { BaseUserService, User, UsersV1Client } from 'cadlearning.angular';
import { CommandNotificationDto } from 'cadlearning.dto';

@Injectable({
	providedIn: 'root',
	deps: [OidcSecurityService, Router, ApplicationInsights, UsersV1Client],
})
export class UserService extends BaseUserService {
	public CommandNotifications: CommandNotificationDto[] = [];
	private AuthToken?: string;

	/* to-do
	 * 1. Add Org Features and Terms Accepted to token
	 */
	constructor(
		private oidcSecurityService: OidcSecurityService,
		private router: Router,
		@Inject(APP_INSIGHTS) private appInsights: ApplicationInsights,
		private userClient: UsersV1Client,
		private configurationService: ConfigurationService
	) {
		super();
	}

	public Init() {
		this.oidcSecurityService.checkAuth().subscribe((loginResponse) => {
			this.AuthChanged(loginResponse);
		});
	}

	public async AuthChanged(loginResponse: LoginResponse) {
		try {
			await this.SetAuth(loginResponse);

			const currentUrl = location.pathname + location.search + location.hash;
			let url = sessionStorage.getItem('CurrentPath') ?? currentUrl; //The URL that the user was on before they started login
			sessionStorage.removeItem('CurrentPath');

			if (PASSTHROUGH_URLS.find((u) => currentUrl.startsWith(u))) {
				//ignore session path and use current path in this case
				url = currentUrl;
			}

			if (
				location.href.includes(LOGOUT_PATH) ||
				location.href.includes(FORBIDDEN_PATH) ||
				location.href.includes(UNAUTHORIZED_PATH)
			) {
				url = '/'; //Hard coded to go to home page to override it.
			}

			if (!loginResponse.isAuthenticated) {
				if (PASSTHROUGH_URLS.find((u) => url.startsWith(u))) {
					this.router.navigateByUrl(url);
				} else {
					this.Login(url);

					return; //Don't want to call the event, because we're going to login.
				}
			} else {
				this.router.navigateByUrl(url);
			}

			this.AuthenticationChanged.emit(this.User);
		} finally {
			this.IsAuthenticationReady = true;
		}
	}

	private async SetAuth(loginResponse: LoginResponse) {
		if (!loginResponse.isAuthenticated) {
			this.IsLoggedIn = false;
			this.appInsights.clearAuthenticatedUserContext();
			this.User = null;
			this.AuthToken = null;
		} else {
			this.AuthToken = loginResponse.accessToken;
			this.User = new User(loginResponse.userData);
			this.IsLoggedIn = true;
			this.appInsights.setAuthenticatedUserContext(this.User.UserName, this.User.Id.toString(), true);
		}
	}

	public Login(redirectUrl: string = null, silent = false, thirdPartyOnly = false, thirdPartyProvider: string = null): Promise<boolean> {
		const promise = new Promise<boolean>((resolve) => {
			sessionStorage.setItem('CurrentPath', redirectUrl || location.pathname + location.search);
			this.oidcSecurityService.authorize(AUTH_CONFIG_ID, {
				urlHandler: (authUrl) => {
					if (thirdPartyOnly) authUrl += (!authUrl.includes('?') ? '?' : '&') + 'thirdpartyonly=true';
					if (thirdPartyProvider) authUrl += (!authUrl.includes('?') ? '?' : '&') + `thirdpartyprovider=${thirdPartyProvider}`;

					if (browser !== undefined && browser && browser.runtime && browser.runtime.id) {
						browser.identity.launchWebAuthFlow(
							{
								url: authUrl,
								interactive: !silent,
							},
							(responseUrl: string) => {
								if (responseUrl.indexOf('#') >= 0) {
									responseUrl = responseUrl.substring(responseUrl.indexOf('#') + 1);
								} else if (responseUrl.indexOf('?') >= 0) {
									responseUrl = responseUrl.substring(responseUrl.indexOf('?') + 1);
								}
							}
						);
						resolve(true);
					} else {
						if (silent) authUrl += (!authUrl.includes('?') ? '?' : '&') + 'silent=true';
						location.assign(authUrl);
						resolve(true);
					}
				},
			});
		});

		return promise;
	}

	public Logout() {
		this.oidcSecurityService.logoff();
	}

	public Register3rdParty(provider: number) {
		sessionStorage.setItem('ExternalCurrentPath', location.pathname + location.search);
		const redirectUrl = `${location.protocol}//${location.host}/register-complete.html`;
		location.assign(
			`${this.configurationService.getAllConfigurations()[0].authWellknownEndpoints.issuer
			}identity/account/externallogin?returnUrl=${encodeURIComponent(redirectUrl)}&registerOnly=true&provider=${provider}`
		);
	}

	public async SetLanguage(languageCode = 'EN') {
		languageCode = languageCode.toUpperCase();
		await this.userClient.SetLanguage({
			LanguageCode: languageCode,
		});

		await this.RefreshSession();
	}

	public async SetOrganization(organizationId?: number) {
		await this.userClient.SetOrganization({
			OrganizationId: organizationId,
		});

		await this.RefreshSession();
	}

	public async SetProject(projectId?: number) {
		await this.userClient.SetProject({
			ProjectId: projectId,
		});

		await this.RefreshSession();
	}

	public async AcceptTerms() {
		if (!this.IsLoggedIn) return;
		await this.userClient.AcceptTerms();

		console.log('Accepted Terms');

		await this.RefreshSession();
	}

	public async UpdateAvailability(available: boolean) {
		await this.userClient.UpdateAvailability({
			Available: available,
			UserId: this.User.Id,
		});

		await this.RefreshSession();
	}

	public async RefreshSession() {
		await this.Login(null, true);
		// const loginResponse = await firstValueFrom(this.oidcSecurityService.forceRefreshSession({ 'prompt': 'none' }));
		// this.oidcSecurityService.authorize(null, {

		// })
		// loginResponse.isAuthenticated = this.IsLoggedIn || false;

		// console.log('Login Response: ', loginResponse);
		// await this.AuthChanged(loginResponse);
	}

	public GetAccessToken() {
		return this.AuthToken;
	}

	public GetCookie(cname: string): string | null {
		const name = cname + '=';
		const decodedCookie: string = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return null;
	}

	public SetCookie(cname: string, cvalue: string, exdays: number) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		const expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
	}
}
