import { Component, Injectable, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Component({
	selector: 'expert-menu',
	templateUrl: './expert.component.html',
	styleUrls: ['./expert.component.scss']
})
@Injectable()
export class ExpertMenuComponent implements AfterViewInit {
	@Output() Closed = new EventEmitter();

	constructor() { }

	async ngAfterViewInit() {
	}


	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}
}
