import { Component, EventEmitter, Injectable, Injector, Input, OnInit, Output } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { BaseComponent } from 'cadlearning.angular';

@Component({
	selector: 'q-menu',
	templateUrl: './q.component.html',
	styleUrls: ['./q.component.scss'],
})
@Injectable()
export class QMenuComponent extends BaseComponent<UserService> implements OnInit {
	@Output() Closed = new EventEmitter();
	@Input() InitialMessage?: string = undefined;
	@Input()
	public ProductId?: number = undefined;

	@Input()
	public VersionId?: number = undefined;

	@Input()
	public IsContent?: boolean = undefined;

	constructor(injector: Injector) {
		super(injector);
		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await super.TranslatePage();
	}

	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}
}
