import { isPlatformServer } from '@angular/common';
import { EventEmitter, Inject, Injectable, Injector, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { API_URL } from '@app/constants';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { CommandNotificationDto, QExpertEscallationResponseDto, QMessageDto, Roles } from 'cadlearning.dto';

@Injectable()
export class SignalRService {
	public connectionEstablished = new EventEmitter<ConnectionInfo>();
	public SystemBroadcast = new EventEmitter<any>();
	public OrganizationBroadcast = new EventEmitter<any>();
	public UserBroadcast = new EventEmitter<any>();
	public PluginBroadcast = new EventEmitter<CommandNotificationDto>();

	public PartnerBroadcast = new EventEmitter<any>();
	public QBroadcast = new EventEmitter<QMessageDto>();
	public QExpertBroadcast = new EventEmitter<QMessageDto>();
	public QExpertCompleteBroadcast = new EventEmitter<number>();

	public _recording: boolean = false;
	public PluginRecordCommands: CommandNotificationDto[] = [];

	get PluginRecording() {
		return this._recording;
	}
	set PluginRecording(val: boolean) {
		if (val) {
			this.PluginRecordCommands.splice(0, this.PluginRecordCommands.length);
		}

		this._recording = val;
	}

	private connections: ConnectionInfo[] = [];

	constructor(private userService: UserService, @Inject(API_URL) private apiUrl: string, private injector: Injector) {
		if (isPlatformServer(PLATFORM_ID) || typeof window === 'undefined') return;

		//this.StartConnection();
	}

	public CloseConnections() {
		this.connections.forEach((c) => {
			c.ManuallyStopped = true;
			c.Connection.stop();
		});

		this.connections = [];
	}

	private createConnection(path: string): ConnectionInfo {
		let connection: HubConnection;
		if (this.userService.IsLoggedIn) {
			connection = new HubConnectionBuilder()
				.withUrl(this.apiUrl + '/Hubs/' + path, {
					accessTokenFactory: () => this.userService.GetAccessToken(),
				})
				.configureLogging(LogLevel.Information)
				.build();
		} else {
			connection = new HubConnectionBuilder()
				.withUrl(this.apiUrl + '/Hubs/' + path)
				.configureLogging(LogLevel.Information)
				.build();
		}

		connection.serverTimeoutInMilliseconds = 30 * 1000 * 10; //Server is 30 seconds, should be much longer.
		connection.keepAliveIntervalInMilliseconds = 30 * 1000; //30 seconds. Server will kill after 1 minute of no response.

		const conInfo: ConnectionInfo = {
			Connection: connection,
			ManuallyStopped: false,
			Name: path,
		};
		connection.onclose((error) => {
			if (!conInfo.ManuallyStopped) {
				console.log('Connection Error: ' + error);
				this.retryConnect(connection);
			}
		});

		return conInfo;
	}

	private async retryConnect(connection: HubConnection) {
		setTimeout(async () => {
			try {
				await connection.start();
			} catch {
				this.retryConnect(connection);
			}
		}, 1000 * 5);
	}

	//  check in the browser console for either signalr connected or not
	public StartConnection(): void {
		const self = this;
		const systemConnection = this.createConnection('Web');
		systemConnection.Connection.on('Broadcast', (message) => {
			self.SystemBroadcast.emit(message);
		});
		this.connections.push(systemConnection);

		if (this.userService.User) {
			const orgConection = this.createConnection('Organizations');
			orgConection.Connection.on('Broadcast', (message) => {
				self.OrganizationBroadcast.emit(message);
			});
			this.connections.push(orgConection);

			if (this.userService.User.IsInRole(Roles.PartnerAdmin, Roles.PartnerSales)) {
				const partnerConnection = this.createConnection('Partners');
				partnerConnection.Connection.on('Broadcast', (message) => {
					self.PartnerBroadcast.emit(message);
				});
				this.connections.push(partnerConnection);
			}

			const userConnection = this.createConnection('Users');
			userConnection.Connection.on('Broadcast', (message) => {
				self.UserBroadcast.emit(message);
			});
			this.connections.push(userConnection);

			const pluginConnection = this.createConnection('Plugin');
			pluginConnection.Connection.on('Broadcast', (message) => {
				self.PluginBroadcast.emit(message);
			});
			this.connections.push(pluginConnection);

			pluginConnection.Connection.on('BroadcastData', (message: CommandNotificationDto) => {
				if (self.PluginRecording) {
					self.PluginRecordCommands.push(message);
				}

				self.PluginBroadcast.emit(message);
			});
		}

		const qConnection = this.createConnection('Q');
		qConnection.Connection.on('BroadcastData', (message: QMessageDto) => {
			self.QBroadcast.emit(message);
		});

		qConnection.Connection.on('BroadcastExpertData', (message: QMessageDto) => {
			self.QExpertBroadcast.emit(message);
		});

		qConnection.Connection.on('BroadcastExpertComplete', (conversationId: number) => {
			self.QExpertCompleteBroadcast.emit(conversationId);
		});
		this.connections.push(qConnection);

		this.connections.forEach((c) => {
			c.Connection.start()
				.then((data: any) => {
					if (data) console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
					this.connectionEstablished.emit(c);
				})
				.catch((error: any) => {
					console.log('Could not connect ' + error);
				});
		});
	}

	public SendMessage(message: QMessageDto) {
		const qConnection = this.connections.find((c) => c.Name === 'Q').Connection;
		qConnection.invoke('SendMessage', message);
	}

	public SendProductAndVersionId(productId?: number, versionId?: number) {
		const qConnection = this.connections.find((c) => c.Name === 'Q').Connection;
		//qConnection.invoke('SendMessage', message);
	}

	public RespondToEscallationRequest(message: QExpertEscallationResponseDto) {
		const qConnection = this.connections.find((c) => c.Name === 'Q').Connection;
		qConnection.invoke('RespondToEscallationRequest', message);
	}
}

@NgModule({
    declarations: [],
    imports: [],
    exports: []
})
export class SignalRModule {
	static forRoot(): ModuleWithProviders<SignalRModule> {
		return {
			ngModule: SignalRModule,
			providers: [SignalRService],
		};
	}
}

interface ConnectionInfo {
	Name: string;
	Connection: HubConnection;
	ManuallyStopped: boolean;
}
