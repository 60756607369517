<div class="bg-primary side-bar-header">
	<a href="#" (click)="Close($event)" class="close pull-right" id="btnClosePlaylist">
		<i class="fa-solid fa-square-xmark" aria-hidden="true"></i>
	</a>
	<h5>{{'Playlists' | translate}}</h5>
</div>
<div class="side-bar-content" (scroll)="SideBarScrolled($event)" [scrollTop]="ScrollPosition">
	<a href="#" id="new-playlist" class="title" (click)="NewPlayList($event)">
		<i class="fa fa-plus" aria-hidden="true"></i>&nbsp;{{'NewPlaylist' | translate}}</a>
	<kendo-panelbar (panelChange)="PlayListChange($event)" [class.cl-light-theme]="IsLight"
		[class.cl-dark-theme]="IsDark">
		<kendo-panelbar-item *ngFor="let playList of PlayLists" id="{{'PlayList' + playList.Id}}" title=""
			expanded="{{playList.Id.toString() == SelectedPlayListId}}">
			<ng-template kendoPanelBarItemTitle>
				<div class="row w-100">
					<div class="col-10">
						{{playList.Name}}
					</div>
					<div class="col-2 text-right">
						<i class="fa fa-pencil-square-o" aria-hidden="true"
							(click)="RenamePlaylist(playList, $event)"></i>
					</div>
				</div>
			</ng-template>
			<ng-template kendoPanelBarContent>
				<kendo-sortable (dragEnd)="onDragEnd(playList, $event)" [kendoSortableBinding]="playList.Contents"
					[navigatable]="true" [zone]="'playlist' + playList.Id" [animation]="true" activeItemClass="active"
					emptyText="{{'AddItemsToYourPlaylist' | translate}}">
					<ng-template let-item="item">
						<div class="row">
							<div class="col-auto">
								<a routerLink="/lesson/{{item.ContentItemId}}" [queryParams]="{playListId: playList.Id}"
									(click)="OpenLesson()">
									<i class="fa fa-play-circle play"></i>
								</a>
							</div>
							<div class="col">
								<a routerLink="/lesson/{{item.ContentItemId}}" [queryParams]="{playListId: playList.Id}"
									(click)="OpenLesson()">{{item.ContentItemName}}&nbsp;&nbsp;&nbsp;
									<i class="fa fa-check" [hidden]="!item.PercentComplete" aria-hidden="true"></i>
								</a>
							</div>
							<div class="col-auto">
								<a (click)="DeleteLesson(playList, item, $event)"
									title="{{'RemoveLesson' | translate}}">
									<i class="fa fa-trash-o" aria-hidden="true"></i>
								</a>&nbsp;&nbsp;&nbsp;
							</div>
							<div class="col-auto">
								<i class="fa fa-sort" aria-hidden="true"></i>
							</div>
						</div>
					</ng-template>
				</kendo-sortable>
				<br />
				<div class="text-center">
					<div class="btn-group btn-group-sm" role="group" aria-label="Playlist Options">
						<button type="button" class="btn btn-primary" id="btnSharePlaylist"
							(click)="Share(playList, $event)">{{'Share'
							| translate}}</button>
						<button type="button" class="btn btn-success" id="btnTakeAssessment"
							(click)="Assessment(playList, $event)">{{'Assess'
							| translate}}</button>
						<button type="button" class="btn btn-danger" id="btnDeletePlaylist"
							(click)="Delete(playList, $event)">{{'Delete'
							| translate}}</button>
					</div>
				</div>
			</ng-template>
		</kendo-panelbar-item>
	</kendo-panelbar>
</div>
