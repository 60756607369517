<span class="close-button">
	<i class="fa fa-times" aria-hidden="true"></i>
</span>
<section id="reports-container" class="top-menu" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark">
	<div class="row">
		<div class="col-12 col-sm-6 col-md-3">
			<div class="card card-list top-card-menu">
				<div class="card-header"><i class="fa-solid fa-wave-pulse"></i>&nbsp;Activity</div>
				<div class="card-body">
					<ul class="list-group list-group-flush">
						<!-- <li class="list-group-item icon">
							<a routerLink="/reports/activity/reportdesigner"><i class="fa-solid fa-pen-ruler"></i>Report
								Designer</a>
							<em>Create a custom report based on activity data</em>
						</li>
						<li class="list-group-item rule">
						</li> -->
						<li class="list-group-item">
							<a routerLink="/reports/activity/lessons">Lesson
								Activity</a>
							<em>Most viewed lessons in your organization</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/resources">Resource
								Activity</a>
							<em>Most viewed resources in your organization</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/products">Product
								Activity</a>
							<em>Most viewed products in your organization</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/productcommands">Product Command
								Activity</a>
							<em>Most used commands by product</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/productversions">Product Version
								Activity</a>
							<em>Activity by product and version</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/users">Employee
								Activity</a>
							<em>Most active employees by your organization</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/groups">Group
								Activity</a>
							<em>Activity by group</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/groupbyproduct">Group
								Activity by Product</a>
							<em>Activity by group and product</em>
						</li>

						<li *ngIf="IsPartner" class="list-group-item">
							<a routerLink="/reports/activity/signupsbymonth">Sign-Ups by
								Month</a>
							<em>New user sign-ups per month</em>
						</li>
						<li *ngIf="IsPartner" class="list-group-item">
							<a routerLink="/reports/activity/signupsbymonthdetail">Sign-Ups by
								Month Detail</a>
							<em>Detailed report of new sign-ups by month</em>
						</li>
						<li class="list-group-item rule">
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/activity/download"><i class="fa-solid fa-download"></i>Download
								Activity</a>
							<em>Download your activity data in Excel or CSV format to use in external analytics
								tools.</em>
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/courses/download"><i class="fa-solid fa-download"></i>Download
								Course</a>
							<em>Download all of your courses in a standardized format for LMSes and other 3rd
								party systems.</em>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3">
			<div class="card card-list top-card-panel">
				<div class="card-header"><i class="fa-solid fa-award"></i>&nbsp;Achievements</div>
				<div class="card-body">
					<ul class="list-group list-group-flush">
						<!-- <li class="list-group-item icon">
							<a routerLink="/reports/assessments/reportdesigner"><i
									class="fa-solid fa-pen-ruler"></i>Report Designer</a>
							<em>Create a custom report based on assessment data</em>
						</li> -->
						<!-- <li class="list-group-item rule">
						</li> -->
						<li class="list-group-item">
							<a routerLink="/reports/assessments/rolesummary">Role Summary by Employee</a>
							<em>Current status of all roles assigned to your Employees</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/goalsummary">Goal Summary By Employee</a>
							<em>Current status of all goals per Employee</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/reportcard">Assessment Report Card</a>
							<em>Report on detailed scoring including question by question results for your employees by
								Assessment.</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/questiondistribution">Assessment Summary</a>
							<em>Analyse the spread of scores summarized by assessment taken by your users.</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/history">Assessment History</a>
							<em>A grid of employees with their historical results by the assessment selected to see
								progress
								over time.</em>
						</li>
						<li class="list-group-item rule">
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/assessments/download"><i class="fa-solid fa-download"></i>Download
								Assessment Data</a>
							<em>Allows you to download your organization's assessment data in Excel or CSV
								format.</em>
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/assessments/downloadroles"><i
									class="fa-solid fa-download"></i>Download Role Data</a>
							<em>Allows you to download your organization's role data in Excel or CSV
								format.</em>
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/assessments/downloadgoals"><i
									class="fa-solid fa-download"></i>Download Goal Data</a>
							<em>Allows you to download your organization's goal data in Excel or CSV
								format.</em>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3">
			<div class="card card-list top-card-panel">
				<div class="card-header"><i class="fa-solid fa-users"></i>&nbsp;Employees</div>
				<div class="card-body">
					<ul class="list-group list-group-flush">
						<!-- <li class="list-group-item icon">
							<a routerLink="/reports/assessments/reportdesigner"><i
									class="fa-solid fa-pen-ruler"></i>Report Designer</a>
							<em>Create a custom report based on employee data</em>
						</li> -->
						<!-- <li class="list-group-item rule">
						</li> -->
						<li class="list-group-item">
							<a routerLink="/reports/activity/users">Activity</a>
							<em>Most active
								employees by your organization</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/groups">Group
								Activity</a>
							<em>Activity by group</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/activity/groupbyproduct">Group
								Activity by Product</a>
							<em>Activity by group and product</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/goalsummary">Goal Summary By Employee</a>
							<em>Current status of all goals per Employee</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/employeesassigntoroles">Employees Assigned to Roles</a>
							<em>Lists Assigned Roles and the Employees with status associated with them.</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/employeesassigntogoals">Employees Assigned to Goals</a>
							<em>Lists Assigned Goals and the Employees with status associated with them.</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/assessments/reportcard">Assessment Report Card</a>
							<em>Report on detailed scoring including question by question results for your employees by
								Assessment.</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/employees/bygroup">Employees By Group</a>
							<em>Employees listed by group</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/employees/bydivision">Employees By Division</a>
							<em>Employees listed by division</em>
						</li>
						<li class="list-group-item">
							<a routerLink="/reports/employees/bylocation">Employees By Location</a>
							<em>Employees listed by location</em>
						</li>
						<li class="list-group-item rule">
						</li>
						<li class="list-group-item icon">
							<a routerLink="/reports/employees/download"><i class="fa-solid fa-download"></i>Download
								Employee Data</a>
							<em>Allows you to download your organization's employee data in Excel or CSV
								format.</em>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3">
			<div class="card card-list top-card-panel">
				<div class="card-header">Custom Reports</div>
				<div class="card-body">
					<ul class="list-group list-group-flush">
						<li class="list-group-item icon">
							<a routerLink="/reports/custom/list"><i class="fa-regular fa-file-chart-column"></i> Custom
								Reports</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="card card-list top-card-panel">
				<div class="card-header"><i class="fa-solid fa-circle-question"></i>Reporting Tips and
					Tricks</div>
				<div class="card-body">
					<ul class="list-group list-group-flush">
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784562501" target="_blank"
								rel="noopener"><i class="fa-solid fa-play"></i>Getting
								Started</a>
						</li>
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784563106" target="_blank"
								rel="noopener"><i class="fa-solid fa-filters"></i>Filtering
								and
								Grouping</a>
						</li>
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784565341" target="_blank"
								rel="noopener"><i class="fa-solid fa-calendar-circle-plus"></i>Scheduling
								Reports</a>
						</li>
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784564165" target="_blank"
								rel="noopener"><i class="fa-solid fa-chart-tree-map"></i>Creating Custom
								Reports</a>
						</li>
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784565698" target="_blank"
								rel="noopener"><i class="fa-solid fa-download"></i>Downloading
								Data</a>
						</li>
						<li class="list-group-item icon">
							<a href="https://vimeo.com/showcase/10068658/video/784565765" target="_blank"
								rel="noopener"><i class="fa-brands fa-microsoft"></i>Using
								Power BI</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>