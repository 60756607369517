import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { Roles } from 'cadlearning.dto';

@Injectable()
export class CanActivateOrganizationGuard implements CanActivate {
	constructor(private userService: UserService) { }

	canActivate() {
		let activate = this.userService.User.IsInRole(Roles.CADAdmin, Roles.OrgAdmin, Roles.UserAdmin, Roles.Reporting, Roles.OrgContentCreator, Roles.QuestionApprover, Roles.ScriptApprover, Roles.NarrationApprover, Roles.FinalApprover, Roles.LearningPathAdmin);
		return activate;
	}
}
