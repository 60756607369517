import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
	apiUrl,
	AUTH_CONFIG_ID,
	CLIENT_ID,
	DEFAULT_LOGIN_REDIRECT_PATH,
	FORBIDDEN_PATH,
	LOGOUT_PATH,
	UNAUTHORIZED_PATH
} from '@app/constants';
import { AbstractSecurityStorage, AuthInterceptor, AuthModule, EventTypes, LogLevel, PublicEventsService } from 'angular-auth-oidc-client';
import { BaseUserService, DefaultLocalStorage } from 'cadlearning.angular';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				authority: apiUrl(),
				configId: AUTH_CONFIG_ID,
				redirectUrl: window.location.origin,
				postLogoutRedirectUri: LOGOUT_PATH,
				postLoginRoute: DEFAULT_LOGIN_REDIRECT_PATH,
				clientId: CLIENT_ID,
				scope: 'openid profile email offline_access',
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				secureRoutes: [apiUrl()],
				logLevel: environment.production ? LogLevel.Error : LogLevel.Debug,
				autoUserInfo: false,
				forbiddenRoute: FORBIDDEN_PATH,
				autoCleanStateAfterAuthentication: true,
				ignoreNonceAfterRefresh: true,
				triggerAuthorizationResultEvent: true,
				maxIdTokenIatOffsetAllowedInSeconds: 600,
				unauthorizedRoute: UNAUTHORIZED_PATH,
				renewTimeBeforeTokenExpiresInSeconds: 20,
				ngswBypass: true,
				renewUserInfoAfterTokenRenew: true,
			},
		}),
	],
	providers: [
		{
			provide: BaseUserService,
			useExisting: UserService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: AbstractSecurityStorage,
			useClass: DefaultLocalStorage,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (userService: UserService) => () => AuthConfigModule.Setup(userService),
			deps: [UserService],
			multi: true,
		},
	],
	exports: [AuthModule],
})
export class AuthConfigModule {
	constructor(private eventService: PublicEventsService, userService: UserService) {
		this.eventService
			.registerForEvents()
			.pipe(first((notification) => notification.type === EventTypes.ConfigLoaded))
			.subscribe((config) => {
				console.log('ConfigLoaded', config);
			});

		// this.eventService
		// 	.registerForEvents()
		// 	.pipe(first((notification) => notification.type === EventTypes.TokenExpired))
		// 	.subscribe((config) => {
		// 		console.log('Token Expired', config);
		// 	});

		// this.eventService
		// 	.registerForEvents()
		// 	.pipe(first((notification) => notification.type === EventTypes.SilentRenewStarted))
		// 	.subscribe((config) => {
		// 		console.log('Silent Renew Started', config);
		// 	});
	}

	static Setup(userService: UserService) {
		userService.Init();
	}
}
