<div class="side-bar-header">
	<a href="#" (click)="Close($event)" class="close pull-right" id="btnCloseYourPath">
		<i class="fa-solid fa-square-xmark" aria-hidden="true"></i>
	</a>
	<h5>{{'Your Progress' | translate}}</h5>
</div>
<div class="side-bar-content" (scroll)="SideBarScrolled($event)" [scrollTop]="ScrollPosition">
	<ul id="badge-path-list" class="list-unstyled" *ngIf="Loaded">
		<li *ngFor="let badge of LearningPath">
			<div class="row badge-path" id="yourpath-badge-{{badge.Id}}">
				<div class="col-2 badge-path-image align-self-center">
					<a style="cursor:pointer;" (click)="ToggleLearningPathExpand(badge)">
						<img *ngIf="!badge.EarnedOn" src="/img/gamification/badge_w.png" title="{{badge.Name}}"
							alt="{{badge.Name}}" class="img-fluid" />
						<img *ngIf="badge.EarnedOn" src="/img/gamification/badge.png" title="{{badge.Name}}"
							alt="{{badge.Name}}" class="img-fluid" />
					</a>
					<div class="progress" *ngIf="badge.PercentComplete && badge.PercentComplete < 100"
						title="{{badge.PercentComplete | number:'1.0-2'}}%">
						<div class="progress-bar" role="progressbar" [style.width]="badge.PercentComplete +'%'"
							attr.aria-valuenow="{{badge.PercentComplete}}" attr.aria-valuemin="0"
							attr.aria-valuemax="100">
						</div>
					</div>

				</div>
				<div class="col-8 align-self-center badge-path-name">
					<a style="cursor:pointer;" (click)="ToggleLearningPathExpand(badge)">{{badge.Name}}</a>
					<span *ngIf="badge['Expanded']" class="assess" (click)="Assess(badge)"><i
							class="fa fa-question-circle-o" aria-hidden="true"></i>&nbsp;&nbsp;{{'Assess' |
						translate}}</span>
				</div>
				<div class="col-1 badge-path-icon align-self-start">
					<a (click)="ToggleLearningPathExpand(badge)" class="right" style="cursor: pointer;">
						<i class="fa fa-chevron-down" *ngIf="!badge['Expanded']" aria-hidden="true"></i>
						<i class="fa fa-chevron-up" *ngIf="badge['Expanded']" aria-hidden="true"></i>
					</a>
				</div>
				<!-- <div class="badge-path-progress-bar col-10 offset-1" *ngIf="!badge.EarnedOn">
							<div class="progress" title="{{badge.Percentage | number:'1.0-2'}}%">
								<div class="progress-bar" role="progressbar" [style.width]="badge.Percentage +'%'"
									attr.aria-valuenow="badge.Percentage" attr.aria-valuemin="0"
									attr.aria-valuemax="100">
								</div>
							</div>
						</div> -->
				<div class="w-100"></div>
				<div class="col-10 offset-2 badge-path-lessons">
					<ul class="list-unstyled" [@openClose]="badge['Expanded'] ? 'open' : 'closed'">
						<li *ngFor="let lesson of badge.Lessons">
							<div class="row badge-path-lesson">
								<div class="col-auto no-padding">
									<i class="fa fa-check play" *ngIf="lesson.Earned" style="color: green;"></i>
									<i class="fa fa-play-circle play" *ngIf="!lesson.Earned"></i>
								</div>
								<div class="col">
									<a class="orange" (click)="LessonClicked()"
										routerLink="/lesson/{{lesson.Id}}">{{lesson.Name}}</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</li>
	</ul>
</div>