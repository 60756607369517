import { Component, Injectable, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/authentication/user.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { BaseComponent, IsValidEmail, PlaylistsV1Client } from 'cadlearning.angular';
import { PlayListUserDto, PlayListWithContentDto } from 'cadlearning.dto';

@Component({
	templateUrl: './playlistshare.component.html',
	styleUrls: ['./playlistshare.component.scss'],
})
@Injectable()
export class PlayListShareComponent extends BaseComponent<UserService> implements OnInit {
	@Input() public PlayList: PlayListWithContentDto = <any>{};

	public Form: UntypedFormGroup = undefined!;
	public Loading: boolean = true;

	public Invitees: PlayListUserDto[] = [];

	constructor(
		private playlistClient: PlaylistsV1Client,
		private fb: UntypedFormBuilder,
		private modalService: DialogRef,
		public injector: Injector
	) {
		super(injector);
		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await this.TranslatePage();

		this.Form = this.fb.group({
			EmailAddress: ['', Validators.compose([Validators.required, IsValidEmail])],
		});

		this.Invitees = await this.playlistClient.ListPlayListUsers(this.PlayList.Id);

		this.Loading = false;
	}

	public async RemoveInvitee(invitee: PlayListUserDto) {
		if (invitee.UserId) await this.playlistClient.DeletePlayListUser(invitee);

		this.Invitees.splice(this.Invitees.indexOf(invitee), 1);
	}

	public AddInvitee() {
		if (!this.Form.valid) {
			for (const c in this.Form.controls) this.Form.controls[c].markAsTouched();

			return;
		}

		this.Invitees.push({
			PlayListId: this.PlayList.Id,
			User: this.Form.controls.EmailAddress.value,
			UserId: undefined,
		});

		this.Form.controls.EmailAddress.setValue('');
		this.Form.controls.EmailAddress.markAsUntouched();
	}

	public async SaveAndClose() {
		// Send the share request here.
		const emailAddresses = (this.Invitees.filter((i) => !i.UserId).map((i) => i.User) as string[] | undefined) ?? [];

		if (emailAddresses.length > 0)
			await this.playlistClient.InviteEmailAddressesToPlayList({
				EmailAddresses: emailAddresses,
				PlayListId: this.PlayList.Id,
				SiteUrl: window.location.hostname,
			});

		this.modalService.close(null);
	}

	public Cancel() {
		this.modalService.close(null);
	}
}
