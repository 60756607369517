import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AzureModule } from 'cadlearning.angular';
import { PreHireInviteComponent } from '../custom/dialogs/prehireinvite.component';
import { DirectivesModule } from '../directives/directives.module';
import { PlayListNewComponent } from '../main/playlist/playlistnew.component';
import { PlayListShareComponent } from '../main/playlist/playlistshare.component';
import { AddExistingBadgeComponent } from './add-existing-badge.component';
import { AddExistingLessonComponent } from './add-existing-lesson.component';
import { AddExistingMedallionComponent } from './add-existing-medallion.component';
import { EditLessonNoteComponent } from './edit-lesson-note.component';
import { InviteUserComponent } from './invite-user.component';
import { NewBadgeComponent } from './new-badge.component';
import { NewLessonComponent } from './new-lesson.component';
import { NewMedallionComponent } from './new-medallion.component';
import { NewPaymentComponent } from './new-payment.component';
import { NewResourceComponent } from './new-resource.component';
import { PurchaseEntitlementComponent } from './PurchaseEntitlement/purchase-entitlement.component';
import { ReportTranslationProblemComponent } from './report-translation-problem.component';
import { RequestSalesTaxExemptionComponent } from './RequestSalesTaxExemption/RequestSalesTaxExemption.component';

@NgModule({
    declarations: [
        NewPaymentComponent,
        NewResourceComponent,
        PlayListNewComponent,
        PlayListShareComponent,
        InviteUserComponent,
        EditLessonNoteComponent,
        PreHireInviteComponent,
        ReportTranslationProblemComponent,
        PurchaseEntitlementComponent,
        RequestSalesTaxExemptionComponent,
        NewMedallionComponent,
        NewBadgeComponent,
        NewLessonComponent,
        AddExistingLessonComponent,
        AddExistingBadgeComponent,
        AddExistingMedallionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        DirectivesModule.forRoot(),
        DropDownsModule,
        DateInputsModule,
        InputsModule,
        TranslateModule,
        AzureModule,
        DirectivesModule
    ],
    exports: [NewPaymentComponent, NewResourceComponent, InviteUserComponent],
    providers: [CurrencyPipe]
})
export class DialogsModule {
	static forRoot(): ModuleWithProviders<DialogsModule> {
		return {
			ngModule: DialogsModule,
			providers: []
		};
	}
}
