import { Component, EventEmitter, Injectable, Injector, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { BaseComponent, ConfirmService, CoursesV1Client, ResourcesV1Client } from 'cadlearning.angular';
import {
	ContentItemDto,
	ContentItemTypes,
	CourseDto,
	CourseStructureForEditingWithChildrenDto,
	ResourceDto,
	ResourceTypes,
	Roles
} from 'cadlearning.dto';

@Component({
	selector: 'content-list-menu',
	templateUrl: './customcontent.component.html',
	styleUrls: ['./customcontent.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class CustomContentMenuComponent extends BaseComponent<UserService> implements OnInit {
	@Output() Closed = new EventEmitter();

	public CourseStructureMap = new Map<number, CourseStructureForEditingWithChildrenDto[]>();

	public CustomCourses: CourseDto[] = [];

	private ResourcesDownloaded: number[] = [];

	constructor(
		private courseClient: CoursesV1Client,
		private resourceClient: ResourcesV1Client,
		private confirm: ConfirmService,
		injector: Injector
	) {
		/* App Component handles retrieving translations */ super(injector);
		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await this.TranslatePage();

		if (!this.User.IsInRole(Roles.OrgContentCreator)) return;

		this.CustomCourses = await this.courseClient.ListCustomCourses();

		this.IsLoaded = true;
	}

	public async PanelStateChange($event: any[]) {
		const expanded = $event.find((e) => e.expanded);
		if (!expanded) return;

		const courseId = parseInt(expanded.id.replace('CustomCourses', ''));

		if (this.CourseStructureMap.has(courseId)) return;

		const structure = await this.courseClient.GetStructureForEditing(courseId);

		this.CourseStructureMap.set(courseId, structure);
	}

	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}

	public async DownloadStructureResource(item: ContentItemDto, $event: Event) {
		const resource = await this.resourceClient.Get(item.ItemId);

		if (item.ResourceType !== ResourceTypes.Link) {
			if (!this.ResourcesDownloaded.some((r) => r === item.ItemId)) {
				$event.preventDefault();
				await this.DownloadResource(resource, $event);
			}
		} else {
			const target: any = $event.target;
			target.href = resource.Uri;
			target.removeAttribute('(click)');
		}
	}

	public async DownloadResource(resource: ResourceDto, $event: Event) {
		const result = await this.resourceClient.RequestResourceAccessToken(resource.Id, false);
		const target: any = $event.target;

		target.href = resource.Uri + result;
		target.removeAttribute('(click)');
		target.download = resource.Uri + result;

		this.ResourcesDownloaded.push(resource.Id);

		setTimeout(() => {
			target.click();
		}, 100);
	}

	public async SaveCourseStructure(courseId: number, structure: CourseStructureForEditingWithChildrenDto[]) {
		let rOrder = 1;
		structure.forEach((r) => {
			r.Order = rOrder;

			if (r.ChildItems) {
				let ciOrder = r.ChildItems.length - 1;

				for (let j = r.ChildItems.length - 1; j >= 0; j--) {
					const ci = r.ChildItems[j];
					if (<any>ci.ItemType === -1) {
						r.ChildItems.splice(j, 1);
					} else {
						ci.Order = ciOrder;
						ciOrder--;
					}
				}
			}
			rOrder++;
		});

		try {
			await this.courseClient.UpdateCustomCourseStructure({
				CourseId: courseId,
				Structure: structure,
			});
		} catch (e: any) {
			this.confirm.alert(e, 'Error');
		}
	}

	public IsExpanded(node: CourseStructureForEditingWithChildrenDto) {
		return node.ChildItems && node.Expanded;
	}

	public ToggleExpand(item: any) {
		if (item.item.dataItem.ItemType == ContentItemTypes.Topic) item.item.dataItem.Expanded = !item.item.dataItem.Expanded;
	}

	public HandleExpand(node: any) {
		node.dataItem.Expanded = true;
	}

	public HandleCollapse(node: any) {
		node.dataItem.Expanded = false;
	}

	public HasChildren(node: CourseStructureForEditingWithChildrenDto) {
		debugger;
	}

	public IsRootElement(node: CourseStructureForEditingWithChildrenDto, courseId: number) {
		return !node.ParentId || node.ParentId === courseId;
	}
}
