import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { Roles } from 'cadlearning.dto';

@Injectable()
export class CanActivateProjectGuard implements CanActivate {
	constructor(private userService: UserService) { }

	canActivate() {
		return this.userService.User.IsInRole(Roles.CADAdmin, Roles.OrgAdmin, Roles.UserAdmin);
	}
}

@Injectable()
export class CanActivateBotGuard implements CanActivate {
	constructor(private userService: UserService) { }

	canActivate() {
		return this.userService.User.IsInRole(Roles.CADAdmin, Roles.OrgAdmin, Roles.UserAdmin) && this.userService.User.HasBotEscallation;
	}
}

@Injectable()
export class CanActivateAccomplishmentsGuard implements CanActivate {
	constructor(private userService: UserService) { }

	canActivate() {
		return this.userService.User.IsInRole(Roles.CADAdmin, Roles.OrgAdmin, Roles.OrgContentCreator, Roles.UserAdmin, Roles.LearningPathAdmin) && this.userService.User.HasAccomplishments;
	}
}
