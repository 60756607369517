import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { AssessmentsV1Client, BaseComponent, CoursesV1Client, OrganizationsV1Client } from 'cadlearning.angular';
import { AssessmentDto, CourseDto, NotificationDto, NotificationMessageTypes, Roles } from 'cadlearning.dto';

@Component({
	selector: 'organizations',
	templateUrl: './organizations.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss'],
})
export class OrganizationsComponent extends BaseComponent<UserService> implements OnInit {
	@Input() Notifications: NotificationDto[] = [];
	public CustomCourses: CourseDto[] = [];
	public CustomAssessments: AssessmentDto[] = [];

	public IsCustomContentCreator = false;
	public IsReporting = false;
	public IsOrgAdmin = false;
	public IsLearningPathAdmin = false;
	public HasProjects = false;

	public IsApprover = false;

	constructor(
		private organizationClient: OrganizationsV1Client,
		private assessmentClient: AssessmentsV1Client,
		private courseClient: CoursesV1Client,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		injector: Injector
	) {
		super(injector);

		super.OverrideTranslationUri('/account/home');
	}

	async ngOnInit() {
		this.IsCustomContentCreator = this.User.IsInRole(Roles.OrgContentCreator);
		this.IsReporting = this.User.IsInRole(Roles.Reporting);
		this.IsOrgAdmin = this.User.IsInRole(Roles.OrgAdmin, Roles.UserAdmin);
		this.IsApprover = this.User.IsInRole(Roles.QuestionApprover, Roles.ScriptApprover, Roles.NarrationApprover, Roles.FinalApprover);
		this.IsLearningPathAdmin = this.User.IsInRole(Roles.LearningPathAdmin);
		this.HasProjects = this.User.HasProjectManagement;
		if (this.IsCustomContentCreator) {
			[this.CustomCourses, this.CustomAssessments] = await Promise.all([
				this.courseClient.ListManagableCustomCourses(undefined, undefined, 'UpdatedOn desc', undefined, 5),
				this.assessmentClient.ListManagableCustomAssessments(undefined, undefined, 'UpdatedOn desc', undefined, 5),
			]);
		}

		if (this.IsOrgAdmin) {
			let requests = await this.organizationClient.ListPermissionRequests();

			if (requests.length > 0) {
				if (
					!this.Notifications.find((n) => {
						return n.Title === 'You have new permission requests';
					})
				) {
					this.Notifications.push({
						Title: 'You have new permission requests',
						Message: 'Click here to view them',
						Url: '/organization/permissions',
						MessageType: NotificationMessageTypes.Informational,
						ImageUrl: undefined,
					});
				}
			}
		}
	}
}
