<div class="chat">
	<div class="chat-history" [class.show-buttons]="
			CurrentMessage != null &&
			CurrentMessage.Buttons != null &&
			CurrentMessage.Buttons.length > 0
		" #chatHistory>
		<div class="chat-history-message" [class.q]="message.FromParticipant == null" [class.response]="
				message.FromParticipant != null &&
				message.FromParticipant.UserId !== User.Id
			" *ngFor="
				let message of Expert ? Chat.QExpertMessages : Chat.QMessages
			">
			<div class="body" [innerHTML]="message.Message"></div>
			<ul class="lessons">
				<li *ngFor="let lesson of message.Lessons">
					<a routerLink="/lesson/{{ lesson.Id }}">{{
						lesson.Name
						}}</a>
				</li>
			</ul>
			<div class="tag">
				{{ message.DateTime | date: "shortTime" }} -
				{{
				message.FromParticipant ? message.FromParticipant.Name : "Q"
				}}
			</div>
		</div>
	</div>
	<div class="chat-buttons" [class.buttons-only]="
			CurrentMessage != null && CurrentMessage.ButtonOnly
		" *ngIf="
			CurrentMessage &&
			CurrentMessage.Buttons &&
			CurrentMessage.Buttons.length > 0
		">
		<ul>
			<li *ngFor="let btn of CurrentMessage.Buttons">
				<button class="btn btn-primary no-close" (click)="ButtonClick(btn, $event)" Type="button">
					{{ btn.Text }}
				</button>
			</li>
		</ul>
	</div>
	<div class="chat-message" [hidden]="CurrentMessage != null && CurrentMessage.ButtonOnly">
		<form (submit)="SendMessage($event)">
			<div class="input-group mb-3">
				<input type="text" name="Message" [(ngModel)]="Message" class="form-control"
					placeholder="Enter Message..." aria-label="Enter Message" />
				<div class="input-group-append">
					<button class="btn btn-success" type="submit">Send</button>
				</div>
			</div>
		</form>
	</div>
</div>