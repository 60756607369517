import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { Roles } from 'cadlearning.dto';

@Injectable()
export class CanActivatePartnerGuard implements CanActivate {
	constructor(private userService: UserService) {}

	canActivate() {
		return this.userService.User.IsInRole(Roles.CADAdmin, Roles.PartnerAdmin);
	}
}
