import { Component, EventEmitter, Injectable, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { BaseComponent } from 'cadlearning.angular';
import { CommandNotificationDto } from 'cadlearning.dto';

@Component({
	selector: 'plugin-list-menu',
	templateUrl: './plugin.component.html',
	styleUrls: ['./plugin.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class PluginMenuComponent extends BaseComponent<UserService> implements OnInit {
	@Output() Closed = new EventEmitter<boolean>();

	@Input() Notifications: CommandNotificationDto[] = [];
	@Input() IsWindow: boolean = false;

	constructor(injector: Injector) {
		super(injector);
		super.OverrideTranslationUri('/user/plugin');
	}

	async ngOnInit() {
		await this.TranslatePage();
		this.IsLoaded = true;
	}

	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}

	public Detach($event: Event) {
		this.Closed.emit();
	}
}
