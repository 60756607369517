<span class="close-button">
	<i class="fa fa-times" aria-hidden="true"></i>
</span>
<section id="library-contents" class="top-menu" [class.Expanded]="Expanded" *ngIf="IsLoaded"
	[class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark">
	<div *ngIf="!IsLibraryLoaded" class="text-center" style="width: 100%;">
		<i class="fa fa-spin fa-spinner fa-3x" aria-hidden="true"></i>
	</div>
	<ul class="list-inline text-center" *ngIf="IsLibraryLoaded">
		<li *ngFor="let Product of SuggestedProducts">
			<a routerLink="/product/{{ Product.ProductId }}" title="{{ Product.Product }}">
				<img *ngIf="Product.DisplayImageId" width="150" height="80" src="{{ Product.DisplayImageUrl }}"
					alt="{{ Product.Product }}" (error)="FixProductImageUrl(Product, $event)" class="product-image" />
				<img *ngIf="!Product.DisplayImageId" src="/img/products/product_{{ Product.ProductId }}.png"
					alt="{{ Product.Product }}" (error)="FixProductImageUrl(Product, $event)" class="product-image" />
				<span>{{ Product['Name'] }}</span>
			</a>
		</li>
	</ul>
</section>
<div *ngIf="IsLoaded" id="library-expander" class="text-center" [class.hide]="!User">
	<a class="no-close pull-left" target="_blank" *ngIf="User" href="{{ GetRssFeedUrl() }}">
		<i class="fa fa-rss"></i> {{ 'SubscribeToChanges' | translate }}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	<a class="no-close" (click)="ToggleExpand($event)">
		<i class="fa" [class.fa-angle-double-down]="!Expanded" [class.fa-angle-double-up]="Expanded"
			aria-hidden="true"></i>&nbsp;
		<span *ngIf="!Expanded">{{ 'More' | translate }}</span>
		<span *ngIf="Expanded">{{ 'Less' | translate }}</span>&nbsp;
		<i class="fa" [class.fa-angle-double-down]="!Expanded" [class.fa-angle-double-up]="Expanded"
			aria-hidden="true"></i>
	</a>
</div>