import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { BaseComponent, ProjectsV1Client } from 'cadlearning.angular';
import { ProjectDto, Roles } from 'cadlearning.dto';

@Component({
	selector: 'projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss', 'projects.component.scss'],
})
export class ProjectsComponent extends BaseComponent<UserService> implements OnInit {
	public IsOrgAdmin = false;

	public Projects: ProjectDto[] = [];

	constructor(
		public projectClient: ProjectsV1Client,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		injector: Injector
	) {
		super(injector);
	}

	async ngOnInit() {
		this.IsOrgAdmin = this.User.IsInRole(Roles.OrgAdmin, Roles.UserAdmin);

		[this.Projects] = await Promise.all([this.projectClient.List('Id,Name')]);

		this.IsLoaded = true;
	}

	public async SelectProject(project: ProjectDto, $event: Event) {
		$event.preventDefault();

		await this.UserService.SetProject(project?.Id);
	}
}
