import { ModuleWithProviders, NgModule } from '@angular/core';
import { DropzoneDirective } from './dropzone.component';
import { EmptyItemPlaceholderDirective } from './empty-item-placeholder-directive';

@NgModule({
    declarations: [DropzoneDirective,
        EmptyItemPlaceholderDirective],
    imports: [],
    exports: [DropzoneDirective,
        EmptyItemPlaceholderDirective]
})
export class DirectivesModule {
	static forRoot(): ModuleWithProviders<DirectivesModule> {
		return {
			ngModule: DirectivesModule,
			providers: []
		};
	}
}
