import { isPlatformServer } from '@angular/common';
import { EventEmitter, Injectable, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { QExpertEscallationResponseDto, QMessageDto, QMessageTypes, QParticipantTypes } from 'cadlearning.dto';
import { SignalRService } from '../../SignalR/SignalRService';

@Injectable()
export class ChatService {
	public QBroadcast = new EventEmitter<QMessageDto>();
	public QExpertBroadcast = new EventEmitter<QMessageDto>();

	private defaultMessage?: QMessageDto = undefined;

	public QMessages: QMessageDto[] = [];
	public QExpertMessages: QMessageDto[] = [];

	constructor(private userService: UserService, private signalr: SignalRService, private router: Router) {
		const self = this;

		if (isPlatformServer(PLATFORM_ID)) return;

		this.signalr.QBroadcast.subscribe((message: QMessageDto) => {
			if (message.Type === QMessageTypes.Restart) this.ClearQConversation();

			if (!self.QMessages.some((m) => m.Id === message.Id)) {
				self.QMessages.push(message);
				self.QBroadcast.emit(message);
			}
		});
		this.signalr.QExpertBroadcast.subscribe((message: QMessageDto) => {
			if (!self.QExpertMessages.some((m) => m.Id === message.Id)) {
				self.QExpertMessages.push(message);
				self.QExpertBroadcast.emit(message);
			}
		});
		this.signalr.QExpertCompleteBroadcast.subscribe((conversationId) => {
			self.ClearExpertConversation();
			self.router.navigateByUrl('/expert/makequestion/' + conversationId);
		});
	}

	public SetDefaultMessage(message: QMessageDto) {
		if (
			message &&
			(!this.QMessages.length ||
				(this.QMessages.length === 1 && this.defaultMessage && this.QMessages[0].Message === this.defaultMessage.Message))
		) {
			this.QMessages.push(message);
		}

		this.defaultMessage = message;
	}

	public ClearQConversation() {
		if (this.defaultMessage) {
			this.QMessages = [this.defaultMessage];
		} else {
			this.QMessages = [];
		}
	}

	public ClearExpertConversation() {
		this.QExpertMessages = [];
	}

	public ButtonResponse(message: QMessageDto) {
		this.SendMessage(message);
	}

	public RespondToEscallationRequest(message: QExpertEscallationResponseDto) {
		this.QExpertMessages.push({
			ConversationId: message.ConversationId,
			DateTime: new Date(),
			Id: undefined!,
			FromParticipant: {
				AccessToken: undefined!,
				Active: message.Accepted,
				Name: this.userService.User.Name,
				Type: QParticipantTypes.Expert,
				UserId: this.userService.User.Id,
			},
			InResponseToMessageId: undefined,
			isHTML: false,
			Message: message.Accepted ? 'Yes' : 'No',
			TimeStamp: undefined,
			ToParticipants: [],
			Type: QMessageTypes.EscallationExpertRequest,
			VerificationToken: message.VerificationToken,
			ButtonOnly: false,
			Buttons: undefined,
			Lessons: undefined,
		});

		this.signalr.RespondToEscallationRequest(message);
	}

	public SendMessage(message: QMessageDto) {
		if (!message.FromParticipant)
			return;

		//Add the message to the queue
		if (message.FromParticipant.Type === QParticipantTypes.User) {
			this.QMessages.push(message);
			this.QBroadcast.emit(message);
		} else {
			this.QExpertMessages.push(message);
			this.QExpertBroadcast.emit(message);
		}
		this.signalr.SendMessage(message);
	}

	public ConversationId() {
		let message = this.QMessages.find((m) => m.ConversationId != null);
		if (message != null) return message.ConversationId;

		message = this.QExpertMessages.find((m) => m.ConversationId != null);
		if (message != null) return message.ConversationId;

		return null;
	}
}

@NgModule({
    declarations: [],
    imports: [],
    exports: []
})
export class ChatModule {
	static forRoot(): ModuleWithProviders<ChatModule> {
		return {
			ngModule: ChatModule,
			providers: [ChatService],
		};
	}
}
