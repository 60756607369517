<span class="close-button">
	<i class="fa fa-times" aria-hidden="true"></i>
</span>
<div *ngIf="!IsLoaded" class="text-center" style="width: 100%;">
	<i class="fa fa-spin fa-spinner fa-3x" aria-hidden="true"></i>
</div>
<section id="projects-container" class="top-menu" *ngIf="IsLoaded" [class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark">
	<div class="row">
		<div class="col-12 col-sm-6 col-md-2">
			<div class="card card-list top-card-menu" style="height: 100px;">
				<ul class="list-group list-group-flush">
					<li id="top-organization-projects" class="list-group-item">
						<a routerLink="/projects">Projects</a>
					</li>
					<li id="top-organization-project-roles" class="list-group-item">
						<a routerLink="/organization/gamification/jobs">Role Templates</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3">
			<div class="vertical-group" [class.flex-column]="Projects.length >= 1">
				<div class="card organization-list top-card-menu" *ngIf="Projects.length >= 1">
					<div class="card-header">Your Projects</div>
					<div class="card-body your-projects">
						<ul class="list-unstyled">
							<li *ngFor="let project of Projects">
								<a href="#" routerLink="/projects/edit/{{ project.Id }}">{{ project.Name }}
									<span class="fa fa-check" *ngIf="User && User.ProjectId == project.Id"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>