import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { BaseComponent, ReportsV1Client, UsersV1Client } from 'cadlearning.angular';
import {
	NotificationDto,
	OrganizationDto,
	ProjectDto,
	ReportAchievementsTestedOutDto,
	ShortLessonWithCoursesDto,
	UserDto,
} from 'cadlearning.dto';

@Component({
	selector: 'users',
	templateUrl: './users.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss'],
})
export class UsersComponent extends BaseComponent<UserService> implements OnInit {
	@Input() Notifications: NotificationDto[] = [];
	public RecentActivity: ShortLessonWithCoursesDto[] = [];
	public Organizations: OrganizationDto[] = [];
	public Projects: ProjectDto[] = [];
	public TestedOutOfAchivements: ReportAchievementsTestedOutDto = undefined;

	public UserDto: UserDto = <any>{
		Id: this.User ? this.User.Id : null,
		AvailableForEscallation: false,
	};

	public Loading = true;

	constructor(
		private userClient: UsersV1Client,
		private reportClient: ReportsV1Client,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		injector: Injector
	) {
		super(injector);
		super.OverrideTranslationUri('/user/topmenu');
	}

	async ngOnInit() {
		await this.TranslatePage();

		if (!this.User) return;

		this.Loading = false;
		this.IsLoaded = true;

		///Itentionally not in with the rest so that there's no delay.
		[this.UserDto, this.Organizations, this.Projects, this.TestedOutOfAchivements] = await Promise.all([
			this.userClient.Get(this.User.Id),
			this.userClient.ListOrganizations(),
			this.userClient.ListProjects(),
			this.reportClient.GetTestedOutAchievements(),
		]);

		this.userClient.GetRecentActivity().then((results) => {
			this.RecentActivity = results;
		});
	}

	public async Logout($event: Event) {
		$event.preventDefault();
		this.UserService.Logout();
	}

	public async SelectProject(project?: ProjectDto, $event?: Event) {
		if ($event) $event.preventDefault();

		await this.UserService.SetProject(project ? undefined : (project as unknown as ProjectDto).Id); // ????????????
	}

	public async SelectOrganization(organization: OrganizationDto, $event: Event) {
		$event.preventDefault();

		await this.UserService.SetOrganization(organization.Id);
	}

	public async UpdateAvailability() {
		await this.UserService.UpdateAvailability(this.UserDto.AvailableForEscallation);
		this.UserService.AuthenticationChanged.emit(this.UserService.User);
	}

	public AvailableClicked($event: Event) {
		$event.preventDefault();
	}
}
