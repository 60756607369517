import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfirmService, IProblemDetails } from 'cadlearning.angular';
import { APP_INSIGHTS } from '../constants';

@Injectable({
	providedIn: 'root',
	deps: [Location, ConfirmService, APP_INSIGHTS, Injector],
})
export class ErrorHandlerService implements ErrorHandler {
	constructor(
		private confirm: ConfirmService,
		@Inject(APP_INSIGHTS) private appInsights: ApplicationInsights,
		private injector: Injector
	) { }

	public async handleError(error: any) {
		if (error instanceof Error) {
			const detail = error as Error;
			return this.handleProblemDetails({
				detail: detail.stack,
				title: detail.message,
				instance: detail.name,
				type: 'Error',
				status: -1,
			});
		}

		if (error instanceof HttpErrorResponse) {
			if (error.status === 401) return this.forceRelogin();

			let detail: IProblemDetails;
			if (error.error instanceof ErrorEvent) {
				detail = {
					detail: error.error.message,
					instance: error.url,
					status: error.status,
					title: error.statusText,
					type: error.type.toString(),
				};
			} else if (error.error instanceof ProgressEvent) {
				return;
			} else {
				detail = {
					detail: error.message,
					instance: error.url,
					status: error.status,
					title: error.statusText,
					type: error.type.toString(),
				};
			}

			return this.handleProblemDetails(detail);
		}

		if ('status' in error && 'title' in error) {
			const detail = error as IProblemDetails;
			return this.handleProblemDetails(detail);
		}

		if ('error' in error && 'description' in error) {
			const openIdError = error as IOpenIdConnectError;

			if (openIdError.error.includes('Authorization has been denied for this request.')) return this.forceRelogin();

			const detail = {
				title: openIdError.error,
				detail: openIdError.description,
				instance: openIdError.uri,
				type: 'Open Id Connect Error',
				status: 400,
			} as IProblemDetails;

			return this.handleProblemDetails(detail);
		}

		const details = {
			title: 'Unknown Error',
			detail: error,
		} as IProblemDetails;

		return this.handleProblemDetails(details);
	}

	public async handleProblemDetails(details: IProblemDetails) {
		try {
			const error =
				`Title: ${details.title}\nStatus:${details.status}\nDetails: ${details.detail}\nInstance: ${details.instance}\nType:${details.type}` ||
				'Unknown Application Error';
			this.appInsights.trackException({
				exception: new Error(error),
			});

			console.error(error);

			if (typeof window !== 'undefined') return this.confirm.alert(details.detail ?? details.title, details.title);
		} catch {
			alert(details.detail ?? details.title);
		}
	}

	private forceRelogin() {
		if (!this.injector) return;
		return this.injector.get(UserService).Login(window.location.pathname + window.location.search);
	}
}

interface IOpenIdConnectError {
	error: string;
	description: string | null;
	uri: string | null;
}
