<div id="plugin-side" *ngIf="IsLoaded && !IsWindow">
    <div class="bg-info side-bar-header" *ngIf="!IsWindow">
        <div class="row">
            <div class="col-8">
                <h5>{{'Plugin' | translate}}</h5>
            </div>
            <div class="col-4 text-right">

                <a href="#" class="plugin-icon" href="/user/plugin?isapp=true&dark=true" target="_blank" title="Undock"
                    id="btnDetach" style="font-size: 20px">
                    <i class="fa fa-window-restore" (click)="Detach($event)"></i>
                </a>
                <a href="#" class="plugin-icon" (click)="Close($event)" id="btnClosePlugin" style="font-size:20px">
                    <i class="fa-solid fa-square-xmark" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="links pull-right">
        </div>
    </div>
    <div class="side-bar-content">
        <p *ngIf="Notifications.length == 0">
            <a routerLink="/downloads">{{'DownloadThePluginForSelectAutodeskProductsHere' | translate}}</a>.
            {{'YoullReceiveLiveSuggestionsOfContentBasedOnYourContextualUsage' | translate}}
        </p>
        <ul class="list-unstyled notifications">
            <li *ngFor="let notification of Notifications">
                <h5>{{notification.Command}}</h5>
                <p *ngFor="let lesson of notification.Lessons">
                    <a routerLink="/lesson/{{lesson.Id}}">
                        <i class="fa fa-play-circle play"></i>&nbsp;{{lesson.Name}}&nbsp;</a>
                </p>
            </li>
        </ul>
    </div>
</div>
<div id="plugin-window" *ngIf="IsWindow">
    <ul class="list-unstyled notifications">
        <li *ngFor="let notification of Notifications">
            <h5>{{notification.Command}}</h5>
            <p style="color: black" *ngFor="let lesson of notification.Lessons">
                <a routerLink="/lesson/{{lesson.Id}}">{{lesson.Name}}</a>
            </p>
        </li>
    </ul>
</div>