import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { BaseComponent } from 'cadlearning.angular';
import { NotificationDto, Roles } from 'cadlearning.dto';

@Component({
	selector: 'partners',
	templateUrl: './partners.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss'],
})
export class PartnersComponent extends BaseComponent<UserService> implements OnInit {
	@Input() Notifications: NotificationDto[] = [];
	public IsPartnerAdmin = false;
	public IsPartnerSales = false;

	constructor(
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		injector: Injector
	) {
		super(injector);
	}

	async ngOnInit() {
		this.IsPartnerAdmin = this.User.IsInRole(Roles.CADAdmin, Roles.PartnerAdmin);
		this.IsPartnerSales = this.User.IsInRole(Roles.CADAdmin, Roles.PartnerSales);
	}
}
