import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { BaseComponent, OrganizationsV1Client, UsersV1Client } from 'cadlearning.angular';

@Component({
	selector: 'reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss', 'reports.component.scss'],
})
export class ReportsComponent extends BaseComponent<UserService> implements OnInit {
	public HasProjects: boolean = false;
	public IsPartner: boolean = false;


	constructor(
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		injector: Injector,
		private orgClient: OrganizationsV1Client,
		private userClient: UsersV1Client
	) {
		super(injector);
	}

	async ngOnInit() {
		this.HasProjects = this.User.HasProjectManagement;
		this.IsPartner = await this.orgClient.IsPartnerOrganization(this.User.OrganizationId);

	}
}
