import { isPlatformServer, Location } from '@angular/common';
import { AfterContentChecked, Component, Inject, Injector, NgZone, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import {
	DEFAULT_LOGIN_REDIRECT_PATH,
	HelpParameters,
	HIDE_HELP,
	IS_APP,
	IS_DARK_THEME,
	IS_IFRAME,
	IS_LIGHT_THEME,
	LOGO_URL,
	LOGO_URL_LIGHT,
	ROOT_COMPANY_ID,
	SHOW_HELP,
} from '@app/constants';
import { GetHostUrl } from '@app/utilities';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, ConfirmService, OrganizationsV1Client, SearchV1Client, User, UsersV1Client } from 'cadlearning.angular';
import {
	CommandNotificationDto,
	GetLanguages,
	KeyValuePair,
	NotificationDto,
	NotificationMessageTypes,
	QMessageDto,
	QMessageTypes,
	QuickSearchResultDto,
	Roles,
} from 'cadlearning.dto';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { SignalRService } from '../SignalR/SignalRService';

export enum NotificationAreas {
	User = 0,
	Organization = 1,
	Partner = 2,
	Plugin = 3,
	Q = 4,
	Expert = 5,
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss', './appmobile.component.scss', './appprint.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseComponent<UserService> implements OnInit, OnDestroy, AfterContentChecked {
	public UserNotifications: NotificationDto[] = [];
	public OrganizationNotifications: NotificationDto[] = [];
	public PartnerNotifications: NotificationDto[] = [];
	public PluginNotifications: NotificationDto[] = [];
	public PluginCommandNotifications: CommandNotificationDto[] = [];

	public WindowWidth: number;

	public SelectedLanguage = 'EN';
	public Languages: KeyValuePair[] = [
		{
			text: 'English',
			value: 'EN',
		},
	];
	public ShowLanguage = false;

	public IsContentCreator = false;
	public ShowPlayList = false;
	public ShowCustomCourses = false;
	public ShowYourPath = false;
	public ShowPluginMenu = false;
	public ShowQMenu = false;
	public HelpParameters: HelpParameters = {};
	public ShowQTab = false;
	public ShowExpertMenu = false;
	public ShowPluginWindow = false;
	public ShowYourPathMenu = false;
	public ShowReportsMenu = false;
	public ShowProjectsMenu = false;
	public HasProjects: boolean = false;

	public ShowCookieConsent = false;

	public AvailableForEscallation = false;

	public ShowQuickSearch = false;
	public SearchResults: QuickSearchResultDto[] = [];
	public MenusVisible: boolean[] = Array(99).fill(false);
	public SearchForm = this.fb.group({
		SearchPhrase: ['', Validators.required],
	});
	public Year: number = new Date().getFullYear();

	public ShowMenu = false;

	private subQuickSearch?: Subscription = null;

	private AcceptedCookie = null;

	public IsCADSupport = false;
	public IsPreHire = false;

	public WhichSite = 'www.cadlearning.com';

	public IsPartnerOrg: boolean = false;

	public TrueLogoUrl: string = undefined!;

	public IsLoaded = false;

	constructor(
		private router: Router,
		public translate: TranslateService,
		private fb: UntypedFormBuilder,
		private zone: NgZone,
		private confirm: ConfirmService,
		private signalr: SignalRService,
		private userClient: UsersV1Client,
		private orgClient: OrganizationsV1Client,
		private searchClient: SearchV1Client,
		private location: Location,
		public injector: Injector,
		@Inject(IS_IFRAME) public IsIFrame: boolean,
		@Inject(IS_APP) public IsApp: boolean,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		@Inject(LOGO_URL) public LogoUrl: string,
		@Inject(LOGO_URL_LIGHT) public LogoUrlLight: string
	) {
		super(injector);

		super.OverrideTranslationUri('/appmain');

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('EN');

		// the lang to use, if the lang isn't available, it will use the current loader to get them

		this.SelectedLanguage = this.User?.LanguageCode ?? localStorage?.getItem('SelectedLanguage') ?? 'EN';
		translate.use(this.SelectedLanguage);

		SHOW_HELP.subscribe((helpParameters) => {
			this.zone.run(() => {
				this.ShowQMenu = true;
				this.HelpParameters = helpParameters;
			});
		});

		HIDE_HELP.subscribe(() => {
			this.zone.run(() => {
				this.ShowQMenu = false;
				this.HelpParameters.InitialMessage = undefined;
			});
		});

		this.location.onUrlChange(() => {
			this.ShowQTab = this.location.path(false).toLowerCase().indexOf('/account') >= 0;
		});

		this.ShowQTab = this.location.path(false).toLowerCase().indexOf('/account') >= 0;

		if (!isPlatformServer(PLATFORM_ID) && typeof window !== 'undefined') {
			this.WindowWidth = window.document.body.scrollWidth;
			window.onresize = () => {
				this.zone.run(() => {
					this.WindowWidth = window.document.body.scrollWidth;
					if (this.WindowWidth >= 1200) this.ShowMenu = false;
				});
			};
			window.onclick = (e) => {
				this.zone.run(() => {
					const el: any = e.target;
					if (
						!e.defaultPrevented &&
						!this.IsClassPresent(el, 'side-bar-content') &&
						!this.IsClassPresent(el, 'side-menu-button') &&
						!this.IsClassPresent(el, 'k-dialog-content') &&
						!this.IsClassPresent(el, 'btn-help')
					) {
						this.ShowCustomCourses = false;
						this.ShowPlayList = false;
						this.ShowPluginMenu = false;
						this.ShowExpertMenu = false;
						this.ShowQMenu = false;
					}

					if (e.defaultPrevented && (!this.IsClassPresent(el, 'top-menu') || this.IsClassPresent(el, 'no-close'))) return;
					this.ShowQuickSearch = false;
					for (let j = 0; j <= 99; j++) {
						this.MenusVisible[j] = false;
					}
				});
			};
		}

		this.subQuickSearch = this.SearchForm.controls.SearchPhrase.valueChanges
			.pipe(
				filter((searchText) => {
					//This is done here to hide immediately if none of this is valid anymore
					if (searchText && searchText.length > 2) {
						return true;
					} else {
						this.ShowQuickSearch = false;
						return false;
					}
				}),
				debounceTime(400),
				distinctUntilChanged(),
				switchMap((searchText: string) => this.searchClient.QuickSearch(searchText, this.SelectedLanguage))
			)
			.subscribe((results: QuickSearchResultDto[]) => {
				this.SearchResults = results;
				this.ShowQuickSearch = results && results.length > 0;
			});

		this.ShowProjectsMenu = false;

		//Auto update notification
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready.then((reg) => {
				reg.addEventListener('updatefound', () => {
					// An updated service worker has appeared in reg.installing!
					const newWorker = reg.installing;

					newWorker.addEventListener('statechange', () => {
						// Has service worker state changed?
						switch (newWorker.state) {
							case 'installed':
								// There is a new service worker available, show the notification
								if (navigator.serviceWorker.controller) {
								}

								break;
							default:
								break;
						}
					});
				});
			});
		}
	}

	ngOnInit() {
		var header = document.getElementsByTagName('header')[0];

		if (this.IsLight) {
			header.classList.add('cl-light-theme');
		} else {
			header.classList.add('cl-dark-theme');
		}

		var host = GetHostUrl(window.location.hostname);

		if (host.indexOf('anhour.io') >= 0) {
			this.WhichSite = 'www.anhour.io';
		} else {
			this.WhichSite = 'www.cadlearning.com';
		}

		this.UserService.AuthenticationChanged.subscribe((user: User) => {
			this.authChanged(user);
		});
		if (this.UserService.IsAuthenticationReady) this.authChanged(this.UserService.User);
	}

	async ngAfterContentChecked() {
		this.IsContentCreator = this.User && this.User.IsInRole(Roles.OrgContentCreator, Roles.CADAdmin);
		this.SelectedLanguage = this.User?.LanguageCode ?? localStorage?.getItem('SelectedLanguage') ?? 'EN';

		if (this.AcceptedCookie === null) {
			this.ShowCookieConsent = true;
		} else {
			this.AcceptedCookie = JSON.parse(this.UserService.GetCookie('cl_acceptedcookie'));

			if (!this.AcceptedCookie.accepted) {
				this.ShowCookieConsent = true;
			} else {
				this.ShowCookieConsent = false;
			}
		}

		this.SetLanguageList();
		//this.translate.use(this.SelectedLanguage);
	}

	ngOnDestroy() {
		if (this.subQuickSearch) this.subQuickSearch.unsubscribe();
	}

	private async authChanged(user?: User) {
		try {
			this.SelectedLanguage = this.User?.LanguageCode ?? localStorage.getItem('SelectedLanguage') ?? 'EN';

			this.SetLanguageList();

			this.UserService.CommandNotifications = this.PluginCommandNotifications;

			this.ShowProjectsMenu = false;
			if (this.User) {
				//check if logged in
				this.translate.use(this.User.LanguageCode);

				this.IsPreHire = this.User.IsInRole(Roles.PreHire);

				const userInfo = await this.userClient.Get(this.User.Id);
				this.AvailableForEscallation = userInfo.AvailableForEscallation;

				this.ShowProjectsMenu =
					this.User.IsInRole(Roles.CADAdmin) ||
					(this.User.OrganizationId !== ROOT_COMPANY_ID && this.User.IsInRole(Roles.OrgAdmin)); //if is a partner, do NOT show the menu

				this.ShowReportsMenu = this.User.IsInRole(Roles.Reporting);

				this.IsPartnerOrg = await this.orgClient.IsPartnerOrganization(this.User.OrganizationId);
				this.IsCADSupport = this.User && this.User.IsInRole(Roles.CADAdmin, Roles.CADSupport, Roles.CADSupportManager);
			} else {
				this.IsPreHire = false;
				this.AvailableForEscallation = false;
				this.IsPartnerOrg = false;
				this.IsCADSupport = false;
			}

			this.AcceptedCookie = this.UserService.GetCookie('cl_acceptedcookie');
			if (this.AcceptedCookie === null && !this.IsApp) {
				this.ShowCookieConsent = true;
			} else {
				if ((!this.AcceptedCookie || !this.AcceptedCookie.accepted) && !this.IsApp) {
					this.ShowCookieConsent = true;
				} else {
					this.ShowCookieConsent = false;
				}
			}

			if (this.User?.OrganizationGroupLogo) this.LogoUrl = this.User.OrganizationGroupLogo;

			this.setupNotifications();
		} finally {
			this.IsLoaded = true;

			await this.TranslatePage();
		}
	}

	public Login() {
		let redirectUrl = DEFAULT_LOGIN_REDIRECT_PATH;
		if (this.location.path().indexOf('/lesson/') > -1) {
			redirectUrl = this.location.path(true);
		} else if (this.location.path().indexOf('/product/') > -1) {
			redirectUrl = this.location.path(true);
		}
		this.UserService.Login(redirectUrl);
	}

	public Logout() {
		this.UserService.Logout();
	}

	public async SelectLanguage(language?: KeyValuePair) {
		this.zone.run(() => {
			for (let j = 0; j <= 99; j++) {
				this.MenusVisible[j] = false;
			}
		});

		if (this.User) {
			await this.UserService.SetLanguage(language.value);
		} else {
			localStorage.setItem('SelectedLanguage', language.value);
			location.reload();
		}
	}

	public SearchClicked($event: Event) {
		$event.preventDefault();

		this.zone.run(() => {
			for (let j = 0; j <= 99; j++) {
				this.MenusVisible[j] = false;
			}
		});

		this.ShowQuickSearch = this.SearchForm.controls.SearchPhrase.value && this.SearchForm.controls.SearchPhrase.value.length > 2;
	}
	public SearchFocused() {
		this.zone.run(() => {
			for (let j = 0; j <= 99; j++) {
				this.MenusVisible[j] = false;
			}
		});

		this.ShowQuickSearch = this.SearchForm.controls.SearchPhrase.value && this.SearchForm.controls.SearchPhrase.value.length > 2;
	}

	public ToggleMenuVisible(position: number, $event: Event) {
		this.ShowMenu = false;
		for (let j = 0; j <= 99; j++) {
			if (j !== position) {
				this.MenusVisible[j] = false;
			} else {
				this.MenusVisible[j] = !this.MenusVisible[j];
			}
		}
		$event.preventDefault();
	}

	public FirstName() {
		if (!this.User) return null;

		return this.User.FirstName;
	}

	public async Search(event: Event) {
		if (this.subQuickSearch) this.subQuickSearch.unsubscribe();

		if (!this.SearchForm.controls.SearchPhrase.value) {
			event.preventDefault();
			await this.confirm.alert('Please enter search criteria first.');
			return;
		}

		this.ShowQuickSearch = false;

		this.router.navigate(['./search/' + encodeURIComponent(this.SearchForm.controls.SearchPhrase.value)]);
		this.SearchForm.controls.SearchPhrase.setValue('');
	}

	public TogglePlayList($event: Event) {
		$event.preventDefault();
		this.ShowPlayList = !this.ShowPlayList;
	}

	public ToggleQ($event: Event) {
		$event.preventDefault();
		this.ShowQMenu = !this.ShowQMenu;
	}

	public ToggleExpert($event: Event) {
		$event.preventDefault();
		this.ShowExpertMenu = !this.ShowExpertMenu;
	}

	public ToggleCustomCourses($event: Event) {
		$event.preventDefault();
		this.ShowCustomCourses = !this.ShowCustomCourses;
	}

	public ToggleYourPath($event: Event) {
		$event.preventDefault();
		this.ShowYourPath = !this.ShowYourPath;
	}

	public TogglePluginMenu($event: Event) {
		$event.preventDefault();
		this.ShowPluginMenu = !this.ShowPluginMenu;
	}

	public ClosePlayList() {
		this.ShowPlayList = false;
	}

	public CloseCustomContent() {
		this.ShowCustomCourses = false;
	}

	public ClosePluginMenu(detach: boolean) {
		this.ShowPluginMenu = false;
		this.ShowPluginWindow = detach;
	}

	public CloseYourPathMenu() {
		this.ShowYourPath = false;
	}

	public CloseQMenu() {
		this.ShowQMenu = false;
	}

	public CloseExpertMenu() {
		this.ShowExpertMenu = false;
	}

	public ClosePluginWindow() {
		this.ShowPluginWindow = false;
	}

	public CloseCookieConsent() {
		this.ShowCookieConsent = false;
		this.AcceptedCookie = {
			accepted: true,
			date: new Date(),
		};

		this.UserService.SetCookie('cl_acceptedcookie', JSON.stringify(this.AcceptedCookie), 30);
	}

	public GetNavDisplay() {
		if (typeof window === 'undefined' || window.document.body.scrollWidth >= 1200) {
			return 'flex !important';
		} else {
			if (this.ShowMenu) {
				return 'block';
			} else {
				return 'none';
			}
		}
	}

	public ToggleDisplay() {
		this.ShowMenu = !this.ShowMenu;
	}

	private SetLanguageList() {
		switch (location.hostname.toLowerCase()) {
			case 'demoportal.cadlearning.com':
			case 'localhost':
				this.Languages = GetLanguages();
				this.ShowLanguage = true;
				break;
			default:
				if (this.User == null || this.User.HasLanguages == null) {
					this.ShowLanguage = false;
					return;
				}
				const langs = GetLanguages();
				this.Languages = langs.filter((l) => this.User.HasLanguages.some((ol) => ol.toLowerCase() === l.value.toLowerCase()));
				this.ShowLanguage = this.Languages.some((l) => l.value !== 'EN');
				break;
		}
	}

	private IsClassPresent(el: HTMLElement, className: string) {
		if (!el || el.className === null || el.className === undefined || <any>el.className instanceof SVGAnimatedString) return false;

		const classes = el.className.toLowerCase().split(' ');
		if (classes.indexOf(className.toLowerCase()) >= 0) return true;

		if (el.parentElement == null) return false;

		return this.IsClassPresent(el.parentElement, className);
	}

	private subscriptions: Subscription[] = [];

	private initNotifications() {
		this.signalr.CloseConnections();
		this.subscriptions
			.filter((s) => s !== null)
			.forEach((s) => {
				s.unsubscribe();
				s = null;
			});
		this.subscriptions = [];

		const self = this;
		this.subscriptions.push(
			this.signalr.SystemBroadcast.subscribe(async (next: NotificationDto) => {
				await self.notify(next, NotificationAreas.User);
			})
		);

		this.subscriptions.push(
			this.signalr.OrganizationBroadcast.subscribe(async (next: NotificationDto) => {
				await self.notify(next, NotificationAreas.Organization);
			})
		);

		this.subscriptions.push(
			this.signalr.PartnerBroadcast.subscribe(async (next: NotificationDto) => {
				await self.notify(next, NotificationAreas.Partner);
			})
		);

		this.subscriptions.push(
			this.signalr.UserBroadcast.subscribe(async (next: NotificationDto) => {
				await self.notify(next, NotificationAreas.User);
			})
		);

		this.subscriptions.push(
			this.signalr.PluginBroadcast.subscribe(async (next: any) => {
				if (next.Title) {
					await self.notify(next, NotificationAreas.Plugin);
				} else if (next.Lessons) {
					await self.notifyPlugin(next);
				}
			})
		);

		this.subscriptions.push(
			this.signalr.QBroadcast.subscribe(async (next: QMessageDto) => {
				if (!document.hasFocus()) {
					//Check if the q tab is open. If it is, pass to the q tab, if not, notify
					await self.notify(
						{
							ImageUrl: null,
							Message: this.html2text(next.Message),
							MessageType: NotificationMessageTypes.Informational,
							Title: 'Message Received',
							Url: null,
						},
						NotificationAreas.Q
					);
				}
			})
		);

		this.subscriptions.push(
			this.signalr.QExpertBroadcast.subscribe((message: QMessageDto) => {
				if (message.Type === QMessageTypes.EscallationExpertRequest || !document.hasFocus()) {
					//Check if the expert tab is open. If it is, pass to the expert tab, if not, notify
					self.notify(
						{
							ImageUrl: null,
							Message: this.html2text(message.Message),
							MessageType: NotificationMessageTypes.Informational,
							Title: 'Message Received',
							Url: null,
						},
						NotificationAreas.Expert
					);
				}
			})
		);

		this.signalr.StartConnection();
	}

	private disconnectNotifications() {
		this.signalr.CloseConnections();
	}

	private async notifyPlugin(message: CommandNotificationDto) {
		if (message) {
			this.PluginCommandNotifications.splice(0, this.PluginCommandNotifications.length);
			this.PluginCommandNotifications.unshift(message);
		}
	}

	private html2text(html) {
		let d = document.createElement('div');
		d.innerHTML = html;
		return d.textContent || d.innerText || '';
	}

	private async notify(message: NotificationDto, area: NotificationAreas, timeout?: number) {
		if (typeof Notification === 'undefined' || (<any>Notification).permission === 'denied') {
			// alert(message.Message);
			return;
		}

		if ((<any>Notification).permission !== 'granted') {
			try {
				await Notification.requestPermission();
			} catch (err) {
				alert(message.Message);
				return;
			}
		}

		const self = this;

		const notification = new Notification(message.Title, {
			body: message.Message,
			lang: 'en',
			tag: message.Url ? message.Url.replace('www.cadlearning.com', location.host) : location.host,
			icon: message.ImageUrl
				? message.ImageUrl
				: message.MessageType === NotificationMessageTypes.Informational
				? '/img/information.png'
				: message.MessageType === NotificationMessageTypes.Error
				? '/img/error.png'
				: message.MessageType === NotificationMessageTypes.Warning
				? '/img/warning.png'
				: null, // Add the other variations here
		});

		// Add the notification to the right area here based on the area
		switch (area) {
			case NotificationAreas.Organization:
				if (!this.IsNotificationDuplicate(this.OrganizationNotifications, message)) this.OrganizationNotifications.unshift(message);
				break;
			case NotificationAreas.Partner:
				if (!this.IsNotificationDuplicate(this.PartnerNotifications, message)) this.PartnerNotifications.unshift(message);
				break;
			case NotificationAreas.Plugin:
				if (!this.IsNotificationDuplicate(this.PluginNotifications, message)) this.PluginNotifications.unshift(message);
				break;
			default:
				if (!this.IsNotificationDuplicate(this.UserNotifications, message)) this.UserNotifications.unshift(message);
				break;
		}

		if (message.Url) {
			notification.onclick = () => {
				let url = message.Url;
				url = url.replace('https://', '');
				if (url.indexOf('/') > 0) url = url.substr(url.indexOf('/'));
				// Redirect here because they've clicked the url
				self.router.navigateByUrl(url);
			};
		}

		if (timeout) setTimeout(notification.close.bind(notification), timeout);
	}

	private IsNotificationDuplicate(notifications: NotificationDto[], message: NotificationDto) {
		const dupe = notifications.some((m) => {
			return (
				m.ImageUrl === message.ImageUrl &&
				m.Message === message.Message &&
				m.MessageType === message.MessageType &&
				m.Title === message.Title &&
				m.Url === message.Url
			);
		});

		return dupe;
	}

	private setupNotifications() {
		try {
			this.disconnectNotifications();
			this.initNotifications();
			if (this.User) {
				this.userClient.CheckForNotifications();
				this.UserService.CommandNotifications = this.PluginCommandNotifications;
			} else {
				this.UserService.CommandNotifications = null;
			}
		} catch (e) {
			console.error(e);
		}
	}
}
