import { Component, Injectable, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/authentication/user.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { EditBaseComponent, PlaylistsV1Client } from 'cadlearning.angular';
import { PlayListWithContentDto } from 'cadlearning.dto';

@Component({
	templateUrl: './playlistnew.component.html',
	styleUrls: ['./playlistnew.component.scss'],
})
@Injectable()
export class PlayListNewComponent extends EditBaseComponent<UserService> implements OnInit {
	public Form: UntypedFormGroup = undefined!;

	@Input() SaveAutomatically: boolean = true;

	constructor(
		private playlistClient: PlaylistsV1Client,
		private fb: UntypedFormBuilder,
		private modalService: DialogRef,
		public injector: Injector
	) {
		super(injector);
		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await this.TranslatePage();

		this.Form = this.fb.group({
			Name: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(255)])],
		});

		this.IsLoaded = true;
	}

	public async SaveAndClose() {
		if (!this.Form.valid) {
			for (const c in this.Form.controls) this.Form.controls[c].markAsTouched();

			return;
		}

		try {
			this.Working = true;

			let newPlaylist: PlayListWithContentDto = {
				Description: undefined,
				Id: 0,
				Name: this.Form.controls.Name.value,
				Owner: this.User.Name,
				OwnerId: this.User.Id,
				TimeStamp: undefined,
				OwningOrganizationId: undefined,
				OwningOrganization: undefined,
			};

			// Send the share request here.
			let result: PlayListWithContentDto[];
			if (this.SaveAutomatically) {
				result = await this.playlistClient.Upsert([newPlaylist]);
			} else {
				result = [newPlaylist];
			}

			this.modalService.close(result[0]);
		} catch (e) {
			alert(e);
		} finally {
			this.Working = false;
		}
	}

	public Cancel() {
		this.modalService.close(null);
	}
}
