<header class="container-fluid fixed-top" [class.no-chrome]="IsApp || IsIFrame" [class.no-chrome-dark]="IsApp && IsDark">
	<nav class="navbar navbar-expand-lg no-print">
		<button
			class="navbar-toggler navbar-toggler-right"
			type="button"
			data-toggle="collapse"
			data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation"
			(click)="ToggleDisplay()"
		>
			<i class="fa fa-bars" aria-hidden="true"></i>
		</button>
		<a class="navbar-brand" routerLink="/">
			<img
				src="{{ LogoUrl }}"
				id="btnHome"
				onError="this.src = '../../img/logos/cadlearning.com.header-large.png'"
				alt="CADLearning - design your future"
			/>
		</a>
		<div
			class="navbar-collapse justify-content-end"
			id="navbarSupportedContent"
			[style.display]="GetNavDisplay()"
			*ngIf="!this.IsPreHire && this.IsLoaded"
		>
			<ul class="navbar-nav">
				<li
					class="nav-item"
					*ngIf="User"
					[class.selected]="MenusVisible[0]"
					[class.cl-light-theme]="IsLight"
					[class.cl-dark-theme]="IsDark"
				>
					<a
						class="btn"
						href="#Library"
						id="ddmLibrary"
						(click)="ToggleMenuVisible(0, $event)"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						>{{ 'Library' | translate }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[0]" [class.fa-angle-up]="MenusVisible[0]" aria-hidden="true"></i>
					</a>
				</li>
				<li
					class="nav-item"
					*ngIf="User?.IsInRole(23, 26) && IsPartnerOrg"
					[class.selected]="MenusVisible[1]"
					[class.cl-light-theme]="IsLight"
					[class.cl-dark-theme]="IsDark"
				>
					<a
						class="btn"
						href="#Partners"
						id="ddmPartners"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						(click)="ToggleMenuVisible(1, $event)"
						>{{ 'Partner' | translate }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[1]" [class.fa-angle-up]="MenusVisible[1]" aria-hidden="true"></i>
					</a>
				</li>
				<li
					class="nav-item"
					*ngIf="User?.IsInRole(3, 22, 20, 28, 29, 30, 31, 32, 35)"
					[class.selected]="MenusVisible[2]"
					[class.cl-light-theme]="IsLight"
					[class.cl-dark-theme]="IsDark"
				>
					<a
						class="btn"
						href="#Organizations"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						id="ddmOrganizations"
						(click)="ToggleMenuVisible(2, $event)"
						>{{ 'Organization' | translate }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[2]" [class.fa-angle-up]="MenusVisible[2]" aria-hidden="true"></i>
					</a>
				</li>
				<li
					class="nav-item"
					*ngIf="ShowProjectsMenu"
					[class.selected]="MenusVisible[4]"
					[class.cl-light-theme]="IsLight"
					[class.cl-dark-theme]="IsDark"
				>
					<a
						class="btn"
						href="#Projects"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						id="ddmProjects"
						(click)="ToggleMenuVisible(4, $event)"
						>{{ 'Projects' | translate }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[4]" [class.fa-angle-up]="MenusVisible[4]" aria-hidden="true"></i>
					</a>
				</li>

				<li
					class="nav-item"
					*ngIf="ShowReportsMenu"
					[class.selected]="MenusVisible[5]"
					[class.cl-light-theme]="IsLight"
					[class.cl-dark-theme]="IsDark"
				>
					<a
						class="btn"
						href="#reports"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						id="ddmReports"
						(click)="ToggleMenuVisible(5, $event)"
						>{{ 'Reports' | translate }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[5]" [class.fa-angle-up]="MenusVisible[5]" aria-hidden="true"></i>
					</a>
				</li>
			</ul>
			<form *ngIf="User" class="form-inline my-2 my-lg-0" [formGroup]="SearchForm" (ngSubmit)="Search($event)">
				<div class="input-group mb-2 mr-sm-2 mb-sm-0">
					<input
						class="mr-sm-2"
						type="search"
						placeholder="Search"
						formControlName="SearchPhrase"
						id="txtSearchPhrase"
						(focus)="SearchFocused()"
						(click)="SearchClicked($event)"
					/>
					<button class="input-group-addon btn-orange" id="btnSubmitSearchPhrase" type="submit">
						{{ 'Search' | translate }}
					</button>
				</div>
				<div id="quick-search" *ngIf="ShowQuickSearch">
					<div id="quick-search-container">
						<div id="quick-search-lesson-results" *ngIf="SearchResults != null && SearchResults.length > 0">
							<ul class="list-unstyled">
								<li *ngFor="let product of SearchResults">
									<h5>
										<a
											routerLink="/product/{{ product.ProductId }}"
											[queryParams]="{ versionId: product.VersionId }"
											(click)="ShowQuickSearch = false"
											>{{ product.Product }}&nbsp;<span *ngIf="product.VersionId">({{ product.Version }})</span>
										</a>
									</h5>
									<ul class="list-unstyled">
										<li *ngFor="let lesson of product.Lessons">
											<a routerLink="/lesson/{{ lesson.Id }}" (click)="ShowQuickSearch = false">{{ lesson.Name }}</a>
											<br />
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div id="quick-search-no-results" class="text-center" *ngIf="SearchResults == null || SearchResults.length == 0">
							<em>{{ 'No Matches Found' | translate }}</em>
						</div>
					</div>
				</div>
			</form>
			<ul class="navbar-nav">
				<li *ngIf="ShowLanguage && User" class="language-menu">
					<label for="cboLanguage" id="lblLanguage">{{ 'Language:' | translate }}' </label>&nbsp;
					<kendo-dropdownbutton
						id="cboLanguage"
						[data]="Languages"
						class="btn-language my-2 my-sm-0"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
						[textField]="'text'"
						(itemClick)="SelectLanguage($event)"
					>
						{{ SelectedLanguage }} &nbsp;
						<i class="fa fa-caret-down"></i>
					</kendo-dropdownbutton>
				</li>
				<li *ngIf="User" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark">
					<a
						class="btn user-menu"
						href="#users"
						id="ddmUser"
						(click)="ToggleMenuVisible(3, $event)"
						[class.selected]="MenusVisible[3]"
						[class.cl-light-theme]="IsLight"
						[class.cl-dark-theme]="IsDark"
					>
						<i class="fa fa-user" aria-hidden="true"></i> {{ User?.FirstName }}
						<i class="fa" [class.fa-angle-down]="!MenusVisible[3]" [class.fa-angle-up]="MenusVisible[3]" aria-hidden="true"></i>
					</a>
				</li>
			</ul>
		</div>
	</nav>
</header>
<div id="right-buttons" *ngIf="IsLoaded && (!User || User.HasAcceptedTerms)">
	<a
		href="#"
		id="yourpath-button"
		*ngIf="User && !(IsApp || IsIFrame)"
		class="btn side-menu-button"
		(click)="ToggleYourPath($event)"
		[hidden]="ShowYourPathMenu"
		>{{ 'Your Progress' | translate }}</a
	>
	<a
		href="#"
		id="q-button"
		*ngIf="ShowQTab || User"
		class="btn btn-danger side-menu-button"
		(click)="ToggleQ($event)"
		[hidden]="ShowQMenu"
		>{{ 'Help' | translate }}</a
	>
	<a
		href="#"
		id="play-list-button"
		*ngIf="User && !(IsApp || IsIFrame)"
		class="btn btn-primary side-menu-button"
		(click)="TogglePlayList($event)"
		[hidden]="ShowPlayList"
		>{{ 'Playlists' | translate }}</a
	>
	<a
		href="#"
		id="expert-button"
		class="btn btn-warning side-menu-button"
		*ngIf="(User && AvailableForEscallation && User.HasBotEscallation) || (IsCADSupport && !(IsApp || IsIFrame))"
		(click)="ToggleExpert($event)"
		[hidden]="ShowExpertMenu"
		>{{ 'Assist' | translate }}</a
	>
</div>
<expert-menu
	[style.visibility]="ShowExpertMenu ? 'visible' : 'hidden'"
	(Closed)="CloseExpertMenu()"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></expert-menu>
<yourpath-menu
	*ngIf="ShowYourPath"
	(Closed)="CloseYourPathMenu()"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></yourpath-menu>
<q-menu
	*ngIf="ShowQMenu"
	[InitialMessage]="HelpParameters.InitialMessage"
	[ProductId]="HelpParameters.ProductId"
	[VersionId]="HelpParameters.VersionId"
	[IsContent]="HelpParameters.IsContent"
	(Closed)="CloseQMenu()"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></q-menu>
<play-list-menu
	*ngIf="ShowPlayList"
	(Closed)="ClosePlayList()"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></play-list-menu>
<content-list-menu
	*ngIf="ShowCustomCourses"
	(Closed)="CloseCustomContent()"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></content-list-menu>
<plugin-list-menu
	class="plugin-list-menu"
	*ngIf="ShowPluginMenu"
	[Notifications]="PluginCommandNotifications"
	(Closed)="ClosePluginMenu($event)"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
>
</plugin-list-menu>
<main
	class="container-fluid"
	[class.no-chrome]="IsApp || IsIFrame"
	[class.no-chrome-dark]="IsApp && IsDark"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
	*ngIf="IsLoaded"
>
	<router-outlet></router-outlet>
	<div kendoWindowContainer></div>
</main>
<footer
	class="container-fluid"
	[class.no-chrome]="IsApp || IsIFrame"
	*ngIf="IsLoaded && User"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
>
	<nav *ngIf="User" class="row">
		<div class="col-12 col-md-8 offset-md-2 text-center">
			Copyright &copy; Eagle Point Software {{ Year }}
			<br />
			<a href="https://{{ WhichSite }}/theblast" target="_blank">The Blast</a>&nbsp;&nbsp;|&nbsp;&nbsp;
			<a target="_blank" href="https://www.cadlearning.com/category/knowledge-base/">CADLearning Blog</a>&nbsp;&nbsp;|&nbsp;&nbsp;
			<a href="https://{{ WhichSite }}/support" target="_blank" title="Support">Support</a>
			<br />
			<a href="https://{{ WhichSite }}/terms-of-service" target="_blank" title="View our Terms &amp; Conditions"
				>Terms &amp; Conditions</a
			>&nbsp;&nbsp;|&nbsp;&nbsp;
			<a href="https://eaglepoint.com/privacy-policy" target="_blank" title="View our Privacy Policy">Privacy Policy</a>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a href="https://{{ WhichSite }}/acceptable-use-policy" target="_blank" title="Acceptable Use Policy">Acceptable Use Policy</a
			>&nbsp;&nbsp;|&nbsp;&nbsp;
			<a href="https://{{ WhichSite }}/service-level-agreement" target="_blank" title="Service Level Agreement"
				>Service Level Agreement</a
			>&nbsp;&nbsp;|&nbsp;&nbsp;
			<a href="https://{{ WhichSite }}/refund" target="_blank" title="Refund">Refund</a>
		</div>
		<div id="social-icons-logged-in" class="col-12 col-md-2 text-right" *ngIf="WhichSite === 'www.cadlearning.com'">
			<a href="https://twitter.com/cadlearning">
				<i id="fa-twitter-icon" class="fa fa-twitter-square" aria-hidden="true"></i>
			</a>
			<a href="https://www.facebook.com/CADLearning">
				<i id="fa-facebook-icon" class="fa fa-facebook-square" aria-hidden="true"></i>
			</a>
			<a href="https://www.linkedin.com/company/cadlearning-by-4d-technologies">
				<i id="fa-linkedin-icon" class="fa fa-linkedin-square" aria-hidden="true"></i>
			</a>
			<!--<a href="#"><i id="fa-google-plus-icon" class="fa fa-google-plus-square" aria-hidden="true"></i></a>-->
			<a href="https://www.youtube.com/user/cadlearning">
				<i id="fa-youtube-icon" class="fa fa-youtube-square" aria-hidden="true"></i>
			</a>
		</div>
	</nav>
	<nav class="row justify-content-center" *ngIf="!User">
		<div class="col-6 col-md-2 offset-md-3">
			<h4>
				<a routerLink="/about">About Us</a>
			</h4>
			<ul class="list-unstyled">
				<li>
					<a routerLink="/about" fragment="our-team">Our Team</a>
				</li>
				<li>
					<a routerLink="/about" fragment="our-partners">Our Partners</a>
				</li>
				<li>
					<a routerLink="/about/theblastarchive" fragment="the-blast">The Blast</a>
				</li>
			</ul>
		</div>
		<div class="col-6 col-md-2">
			<h4>Our Solutions</h4>
			<ul class="list-unstyled">
				<li>
					<a routerLink="/markets/smallbusiness">Small &amp; Medium Business</a>
				</li>
				<li>
					<a routerLink="/markets/enterprise">Enterprise</a>
				</li>
				<li>
					<a routerLink="/markets/education">Education</a>
				</li>
			</ul>
		</div>
		<div class="col-6 col-md-2">
			<h4>
				<a routerLink="/contact">Resources</a>
			</h4>
			<ul class="list-unstyled">
				<li>
					<a href="https://www.cadlearning.com/theblast" fragment="the-blast">The Blast</a>
				</li>
				<li>
					<a target="_blank" href="https://www.cadlearning.com/category/knowledge-base">CAD Learning Blog</a>
				</li>
				<li>
					<a href="https://www.cadlearning.com/support" target="_blank" title="Support">Support</a>
				</li>
			</ul>
		</div>
		<div id="social-icons-anonymous" class="col-6 col-md-2 text-right">
			<a href="https://twitter.com/cadlearning" title="Share On Twitter">
				<i id="fa-twitter-icon-anon" class="fa fa-twitter-square" aria-hidden="true"></i>
			</a>
			<a href="https://www.facebook.com/CADLearning" title="Share on Facebook">
				<i id="fa-facebook-icon-anon" class="fa fa-facebook-square" aria-hidden="true"></i>
			</a>
			<a href="https://www.linkedin.com/company/cadlearning-by-4d-technologies" title="Share On Linkedin">
				<i id="fa-linkedin-icon-anon" class="fa fa-linkedin-square" aria-hidden="true"></i>
			</a>
			<!--<a href="#"><i id="fa-google-plus-icon" class="fa fa-google-plus-square" aria-hidden="true"></i></a>-->
			<a href="https://www.youtube.com/user/cadlearning" title="Share On Youtube">
				<i id="fa-youtube-icon-anon" class="fa fa-youtube-square" aria-hidden="true"></i>
			</a>
		</div>
	</nav>
	<nav class="text-center" *ngIf="!User">&copy; {{ Year }} - Eagle Point Software</nav>
	<nav class="text-center" *ngIf="!User">
		<a href="https://www.cadlearning.com/terms-of-service" target="_blank" title="Terms of Service">Terms &amp; Conditions</a
		>&nbsp;&nbsp;|&nbsp;&nbsp;
		<a href="https://eaglepoint.com/privacy-policy" target="_blank" title="View our Privacy Policy">Privacy Policy</a
		>&nbsp;&nbsp;|&nbsp;&nbsp;
		<a href="https://www.cadlearning.com/acceptable-use-policy" target="_blank" title="Acceptable Use Policy">Acceptable Use Policy</a
		>&nbsp;&nbsp;|&nbsp;&nbsp;
		<a href="https://www.cadlearning.com/service-level-agreement" target="_blank" title="Service Level Agreement"
			>Service Level Agreement</a
		>&nbsp;&nbsp;|&nbsp;&nbsp;
		<a href="https://www.cadlearning.com/refund" target="_blank" title="Refund">Refund</a>
	</nav>
</footer>
<div id="cookieConsent" *ngIf="ShowCookieConsent && this.IsLoaded">
	<div id="cookieConsentText">
		This website uses cookies. Please see our
		<a href="https://www.cadlearning.com/terms-of-service">terms of service</a> and
		<a href="https://eaglepoint.com/privacy-policy">privacy policy</a> for more details. &nbsp;&nbsp;
		<button class="btn btn-orange" (click)="CloseCookieConsent()">That's OK</button>
	</div>
</div>

<library id="library" *ngIf="MenusVisible[0]" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark"></library>
<partners
	id="partners"
	*ngIf="MenusVisible[1]"
	[Notifications]="PartnerNotifications"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></partners>
<organizations
	id="organizations"
	*ngIf="MenusVisible[2]"
	[Notifications]="OrganizationNotifications"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></organizations>
<users
	id="users"
	*ngIf="MenusVisible[3]"
	[Notifications]="UserNotifications"
	[class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark"
></users>

<projects id="projects" *ngIf="MenusVisible[4]" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark"> </projects>
<reports id="reports" *ngIf="MenusVisible[5]" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark"></reports>

<div kendoDialogContainer></div>
<kendo-window
	title="Plugin"
	*ngIf="ShowPluginWindow"
	(close)="ClosePluginWindow()"
	[minWidth]="250"
	[width]="450"
	[draggable]="true"
	[keepContent]="true"
>
	<plugin-list-menu [Notifications]="PluginCommandNotifications" [IsWindow]="true"></plugin-list-menu>
</kendo-window>
