import { AfterViewInit, Component, EventEmitter, Inject, Injectable, Injector, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { DragEndEvent } from '@progress/kendo-angular-charts';
import { DialogService } from '@progress/kendo-angular-dialog';
import { BaseComponent, ConfirmService, PlaylistsV1Client } from 'cadlearning.angular';
import { PlayListContentDto, PlayListWithContentDto } from 'cadlearning.dto';
import { PlayListNewComponent } from './playlistnew.component';
import { PlayListShareComponent } from './playlistshare.component';

@Component({
	selector: 'play-list-menu',
	templateUrl: './playlist.component.html',
	styleUrls: ['./playlist.component.scss'],
})
@Injectable()
export class PlayListMenuComponent extends BaseComponent<UserService> implements OnInit, AfterViewInit {
	@Output() Closed = new EventEmitter();

	public PlayLists: PlayListWithContentDto[] = [];
	public SelectedPlayListId?: string = undefined;
	public ScrollPosition = 0;

	constructor(
		private playListClient: PlaylistsV1Client,
		private confirmService: ConfirmService,
		private router: Router,
		private modalService: DialogService,
		public injector: Injector,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean
	) {
		super(injector);
		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await this.TranslatePage();

		if (this.User) {
			this.SelectedPlayListId = localStorage.getItem('SelectedPlayListId') ?? undefined;
			this.PlayLists = await this.playListClient.ListPlaylistByUser();
			this.SelectedPlayListId =
				this.SelectedPlayListId || (this.PlayLists.length > 0 ? 'PlayList' + this.PlayLists[0].Id : undefined);
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			let storageItem = localStorage.getItem('PlayListPosition') ?? '0';
			this.ScrollPosition = parseInt(storageItem);
		}, 1000);
	}

	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}

	public OpenLesson() {
		this.Closed.emit();
	}

	public PlayListChange($event: any[]) {
		let panel: string = $event.find((f) => {
			return f.expanded;
		}).id;
		window.localStorage.setItem('SelectedPlayListId', panel.substr(0, 8));
	}

	public async NewPlayList($event: Event) {
		$event.preventDefault();
		this.Closed.emit();

		// Show the new playlist screen
		const modal = this.modalService.open({
			content: PlayListNewComponent,
		});

		try {
			await modal.result.toPromise();
		} catch (error) {}
	}

	public DeletingLesson: boolean = false;
	public async DeleteLesson(playList: PlayListWithContentDto, item: PlayListContentDto, $event) {
		$event.preventDefault();

		if (!playList.Contents) playList.Contents = [];

		playList.Contents.splice(playList.Contents.indexOf(item), 1);
		playList.Contents = playList.Contents.slice(0);

		//ondragend calls upsert
		this.SavePlaylist(playList);
	}

	public SaveRunning: boolean = false;
	public async SavePlaylist(playList: PlayListWithContentDto) {
		if (this.DragEndRunning || this.SaveRunning) {
			setTimeout(() => {
				this.SavePlaylist(playList);
			}, 200);
			return;
		}

		this.SaveRunning = true;

		try {
			await this.playListClient.Upsert([playList]);
			await this.playListClient.UpsertWithContent(playList);
		} finally {
			this.SaveRunning = false;
		}
	}

	public async Delete(playList: PlayListWithContentDto, $event: Event) {
		$event.preventDefault();
		await this.playListClient.DeleteByIds(playList.Id);
		this.PlayLists.splice(this.PlayLists.indexOf(playList), 1);
	}

	public async Share(playList: PlayListWithContentDto, $event: Event) {
		$event.preventDefault();

		const dialog = this.modalService.open({
			content: PlayListShareComponent,
		});
		dialog.content.instance.PlayList = playList;
		await dialog.result;
	}

	public Assessment(playList: PlayListWithContentDto, $event: Event) {
		$event.preventDefault();
		this.Closed.emit();

		this.router.navigate(['/assessments/takeassessment'], {
			queryParams: { PlayListId: playList.Id },
		});
	}

	public SideBarScrolled($event: Event) {
		localStorage.setItem('PlayListPosition', (<any>$event.srcElement).scrollTop.toString());
	}

	public DragEndRunning: boolean = false;
	public async onDragEnd(playList: PlayListWithContentDto, e: DragEndEvent) {
		if (!playList.Contents) playList.Contents = [];

		this.DragEndRunning = true;

		try {
			for (let i = 0; i < playList.Contents.length; ++i) {
				playList.Contents[i].Order = i;
			}

			await this.playListClient.Upsert([playList]);
			await this.playListClient.UpsertWithContent(playList);
		} finally {
			this.DragEndRunning = false;
		}
	}

	public async RenamePlaylist(playList: PlayListWithContentDto, e: Event) {
		e.preventDefault();

		let newName = await this.confirmService.prompt('Rename Playlist', 'Name', playList.Name, true, 255);

		if (!newName) return;

		playList.Name = newName;

		await this.playListClient.Upsert([playList]);
	}
}
