import { EventEmitter, InjectionToken } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { environment } from 'src/environments/environment';


export class HelpParameters {
	public InitialMessage?: string;
	public ProductId?: number;
	public VersionId?: number;
	public IsContent?: boolean = false;
}

export const ROOT_COMPANY_ID = 100000;
export const CLIENT_ID = 'Portal';
export const SHOW_HELP: EventEmitter<HelpParameters> = new EventEmitter();
export const HIDE_HELP = new EventEmitter();

export const LOGOUT_PATH = `${location.protocol}//${location.host}/account/logout`;
export const FORBIDDEN_PATH = '/account/forbidden';
export const UNAUTHORIZED_PATH = '/account/unauthorized';
export const DEFAULT_LOGIN_REDIRECT_PATH = '/';
export const PASSTHROUGH_URLS = ['/account', '/playback', FORBIDDEN_PATH, LOGOUT_PATH, UNAUTHORIZED_PATH];
export const AUTH_CONFIG_ID = 'main';

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

export const ORIGIN_URL = new InjectionToken<string>('ORIGIN_URL', {
	providedIn: 'platform',
	factory: () => location.origin,
});


export function apiUrl() {
	const host = location.hostname.toLowerCase();
	if (host.endsWith('localhost')) {
		return 'https://localhost:44301';
	} else if (host.endsWith('beta.cadlearning.com')) {
		return 'https://api.beta.cadlearning.com';
	} else if (host.endsWith('local.cadlearning.com')) {
		return 'https://api.local.cadlearning.com';
	} else if (host.endsWith('qa.cadlearning.com')) {
		return 'https://api.qa.cadlearning.com';
	} else if (host.endsWith('staging.cadlearning.com')) {
		return 'https://api.staging.cadlearning.com';
	} else if (host.endsWith('demo.cadlearning.com')) {
		return 'https://api.demo.cadlearning.com';
	} else if (host.endsWith('dev.anhour.io')) {
		return "https://dev.anhour.io:44301";
	} else if (host.endsWith('beta.anhour.io')) {
		return "https://api.beta.anhour.io";
	} else if (host.endsWith('qa.anhour.io')) {
		return "https://api.qa.anhour.io";
	} else if (host.endsWith('staging.anhour.io')) {
		return "https://api.staging.anhour.io";
	} else if (host.endsWith('demo.anhour.io')) {
		return "https://api.demo.anhour.io";
	} else if (host.endsWith('anhour.io')) {
		return "https://api.anhour.io";
	}

	else {
		return 'https://api.cadlearning.com';
	}
}

export const API_URL = new InjectionToken<string>('API_URL', {
	providedIn: 'platform',
	factory: () => {
		return apiUrl();
	},
});

export const BLOB_URL = new InjectionToken<string>('BLOB_URL', {
	providedIn: 'platform',
	factory: getBlobUrl,
});

function getBlobUrl() {
	const host = location.hostname.toLowerCase();
	if (host.endsWith('localhost') || host.endsWith('beta.cadlearning.com') || host.endsWith('local.cadlearning.com') || host.endsWith('dev.anhour.io') || host.endsWith('beta.anhour.io')) {
		return 'https://casabeta.blob.core.windows.net';
	} else if (host.endsWith('qa.cadlearning.com') || host.endsWith('qa.anhour.io')) {
		return 'https://clqasa.blob.core.windows.net';
	} else if (host.endsWith('staging.cadlearning.com') || host.endsWith('staging.anhour.io')) {
		return 'https://clstagingsa.blob.core.windows.net';
	} else if (host.endsWith('demo.cadlearning.com') || host.endsWith('demo.anhour.io')) {
		return 'https://cldemosa.blob.core.windows.net';
	} else {
		return 'https://cadproduction.blob.core.windows.net';
	}
}

export const SIGNALR_URL = new InjectionToken<string>('SIGNALR_URL', {
	providedIn: 'platform',
	factory: () => {
		const host = location.hostname.toLowerCase();

		if (host.endsWith('localhost') || host.endsWith('beta.cadlearning.com') || host.endsWith('local.cadlearning.com') || host.endsWith('dev.anhour.io') || host.endsWith('beta.anhour.io')) {
			return 'https://cl-sr-beta.service.signalr.net';
		} else if (host.endsWith('qa.cadlearning.com') || host.endsWith('qa.anhour.io')) {
			return 'https://cl-sr-qa.service.signalr.net';
		} else if (host.endsWith('staging.cadlearning.com') || host.endsWith('staging.anhour.io')) {
			return 'https://cl-sr-staging.service.signalr.net';
		} else if (host.endsWith('demo.cadlearning.com') || host.endsWith('demo.anhour.io')) {
			return 'https://cl-sr-demo.service.signalr.net';
		} else {
			return 'https://cl-sr-prod.service.signalr.net';
		}
	},
});

export const SITE_URL = new InjectionToken<string>('SITE_URL', {
	providedIn: 'root',
	factory: () => {
		return Sites.includes(location.hostname.toLowerCase()) ? '4d.cadlearning.com' : location.hostname;
	},
});

export const LOGO_URL = new InjectionToken<string>('LOGO_URL', {
	providedIn: 'platform',
	factory: () => {
		let site = '4d.cadlearning.com';

		if (Sites.includes(location.hostname.toLowerCase())) {
			site = '4d.cadlearning.com';
		} else if (AnHourSites.includes(location.hostname.toLowerCase())) {
			site = 'portal.anhour.io';
		} else {
			site = location.hostname;
		}

		return `${getBlobUrl()}/portalstatic/${site}.logo.png`;
	},
});

export const LOGO_URL_LIGHT = new InjectionToken<string>('LOGO_URL_LIGHT', {
	providedIn: 'platform',
	factory: () => {
		let site = '4d.cadlearning.com';

		if (Sites.includes(location.hostname.toLowerCase())) {
			site = '4d.cadlearning.com';
		} else if (AnHourSites.includes(location.hostname.toLowerCase())) {
			site = 'portal.anhour.io';
		} else {
			site = location.hostname;
		}

		return `${getBlobUrl()}/portalstatic/${site}.logo.light.png`;
	},
});

export const IS_CADLEARNING_SITE = new InjectionToken<boolean>('IS_CADLEARNING_SITE', {
	providedIn: 'root',
	factory: () => {
		return Sites.includes(location.hostname.toLowerCase());
	},
});

export const IS_ANHOUR_SITE = new InjectionToken<boolean>('IS_ANHOUR_SITE', {
	providedIn: 'root',
	factory: () => {
		return AnHourSites.includes(location.hostname.toLowerCase());
	},
});

export const IS_IFRAME = new InjectionToken<boolean>('IS_IFRAME', {
	providedIn: 'platform',
	factory: () => {
		const queryString = (location.search || '').toLowerCase();
		return queryString.includes('isframe=true') || (typeof window !== 'undefined' && window.self !== window.top);
	},
});

export const IS_SCORM = new InjectionToken<boolean>('IS_SCORM', {
	providedIn: 'platform',
	factory: () =>
		(location.search || '').toLowerCase().includes('isscorm=true') || (typeof window !== 'undefined' && window.self !== window.top),
});

export const IS_APP = new InjectionToken<boolean>('IS_APP', {
	providedIn: 'platform',
	factory: () => {
		const queryString = (location.search || '').toLowerCase();
		const win = window as any;
		return (
			win.Windows || //Windows Store
			win.navigator.standalone === true || //iOS
			window.matchMedia('(display-mode: standalone)').matches || //Chrome based PWA
			(win.process && win.process.type) || //Electron
			queryString.includes('isapp=true') || //Forced in URL
			location.pathname.startsWith('/playback')
		);
	},
});

function GetCookie(cname: string): string | null {
	let name = cname + '=';
	let decodedCookie: string = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
}

export const THEME_COOKIE = "cl_theme_cookie";

export const IS_LIGHT_THEME = new InjectionToken<boolean>('IS_LIGHT_THEME', {
	providedIn: 'platform',
	factory: () => {
		var theme = GetCookie(THEME_COOKIE);

		if (theme == null || theme == 'platform') {
			//get based on the platform
			const lightThemeMq = window.matchMedia("(prefers-color-scheme: light)");
			return lightThemeMq.matches;
		}
		else {
			return theme == "light";
		}
	},
});

export const IS_DARK_THEME = new InjectionToken<boolean>('IS_DARK_THEME', {
	providedIn: 'platform',
	factory: () => {
		var theme = GetCookie(THEME_COOKIE);

		if (theme == null || theme == 'platform') {
			//get based on the platform
			const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
			return darkThemeMq.matches;
		}
		else {
			return theme == "dark";
		}
	},
});

export const APP_INSIGHTS = new InjectionToken<ApplicationInsights>('APP_INSIGHTS', {
	providedIn: 'root',
	factory: () => {
		return new ApplicationInsights({
			config: {
				instrumentationKey:
					location.hostname.toLowerCase() === 'localhost' ||
						location.hostname.toLowerCase().endsWith('beta.cadlearning.com') ||
						location.hostname.toLowerCase().endsWith('local.cadlearning.com')
						? '2e2b111d-164a-45c4-ab15-089b4754c0dd'
						: location.hostname.toLowerCase().endsWith('qa.cadlearning.com')
							? '3d6e2fab-6c3f-4edf-9513-70b6948ea309'
							: location.hostname.toLowerCase().endsWith('staging.cadlearning.com')
								? 'ebcf3b19-7c07-4c25-830a-6f995f42bdff'
								: location.hostname.toLowerCase().endsWith('demo.cadlearning.com')
									? 'dd64f43a-5620-429a-b2b1-b57c20b10f69'
									: 'c2cfe355-930d-4e63-8406-3a13e5858514',
				autoTrackPageVisitTime: true,
				autoExceptionInstrumented: true,
				enableAutoRouteTracking: true,
				enableDebug: !environment.production,
				enableRequestHeaderTracking: true,
				enableCorsCorrelation: false, //Must be false or videos won't play
				enableDebugExceptions: !environment.production,
				enableResponseHeaderTracking: true,
				loggingLevelTelemetry: environment.production ? 1 : 2,
				loggingLevelConsole: environment.production ? 1 : 2,
			},
		});
	},
});

export const Sites = [
	'localhost',
	'portal.local.cadlearning.com',
	'portal.beta.cadlearning.com',
	'portal.qa.cadlearning.com',
	'portal.staging.cadlearning.com',
	'portal.demo.cadlearning.com',
	'portal.cadlearning.com',
	'4d.cadlearning.com',
];

export const AnHourSites = [
	'dev.anhour.io',
	'portal.beta.anhour.io',
	'portal.qa.anhour.io',
	'portal.staging.anhour.io',
	'portal.anhour.io'
];
