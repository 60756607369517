<form action="POST" novalidate [formGroup]="Form" (ngSubmit)="SaveAndClose()" *ngIf="IsLoaded">
    <div class="modal-header">
        <h4 class="modal-title">{{'NewPlaylist' | translate}}</h4>
    </div>

    <div class="modal-body">
        <div class="form-group" [class.has-danger]="!Form.get('Name')!.valid && Form.get('Name')!.touched"
            [class.has-success]="Form.get('Name')!.valid">
            <label for="txtName">{{'PlaylistName' | translate}}
                <em>*</em>
            </label>
            <input type="text" id="txtName" maxlength="255" required class="form-control" formControlName="Name"
                [class.form-control-danger]="!Form.get('Name')!.valid && Form.get('Name')!.touched"
                [class.form-control-success]="Form.get('Name')!.valid" />
            <div *ngIf="Form.get('Name')!.errors && Form.get('Name')!.touched" class="alert alert-danger">
                <small>{{'PleaseEnterAValidPlaylistNameBetween6And255CharactersLong' | translate}}</small>
            </div>
        </div>
    </div>
    <div class="modal-footer text-right">
        <button type="submit" class="btn btn-orange" id="btnCreate" [disabled]="Working">{{'Create' |
            translate}}</button>
        <button type="button" class="btn btn-warning" id="btnCancel" (click)="Cancel()">{{'Cancel' |
            translate}}</button>
    </div>
</form>