export function nameof<T>(key: keyof T, instance?: T): keyof T {
	return key;
}

export class Guid {
	static newGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
}

export function GetHostUrl(s: string): string {
	let host = s.toLowerCase();
	switch (host) {
		case 'portal.beta.cadlearning.com':
		case 'localhost':
		case 'portal.local.cadlearning.com':
		case 'portal.qa.cadlearning.com':
		case 'portal.staging.cadlearning.com':
		case 'portal.demo.cadlearning.com':
			host = '4d.cadlearning.com';
			break;
		case 'dev.anhour.io':
		case 'portal.beta.anhour.io':
		case 'localhost':
		case 'portal.local.anhour.io':
		case 'portal.qa.anhour.io':
		case 'portal.staging.anhour.io':
		case 'portal.demo.anhour.io':
			host = 'portal.anhour.io';
			break;
		default:
			break;
	}

	return host;
}

export function replaceGuidHyphens(s: any) {
	if (!s) return undefined;
	return s.toString().replace(/-/g, '');
}

export function addGuidHyphens(s: string) {
	if (!s) return undefined;
	return [s.substring(0, 8), s.substring(8, 12), s.substring(12, 16), s.substring(16, 20), s.substring(20, 32)].join('-');
}

export class ColorConverter {
	static getContrastColor(hex) {
		let rgb = ColorConverter.hexToRgb(hex)!;

		let d = 0;

		// Counting the perceptive luminance - human eye favors green color...
		let a = 1 - (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

		if (a < 0.5) d = 0;
		// bright colors - black font
		else d = 255; // dark colors - white font

		return ColorConverter.rgbToHex(d, d, d);
	}

	static hexToRgb(hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}

	static rgbToHex(r, g, b) {
		return '#' + ColorConverter.componentToHex(r) + ColorConverter.componentToHex(g) + ColorConverter.componentToHex(b);
	}

	static componentToHex(c) {
		const hex = c.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}
}
