<span class="close-button"><i class="fa fa-times" aria-hidden="true"></i></span>
<section id="partner-container" class="top-menu" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-2">
            <div class="card card-list top-card-menu">
                <ul class="list-group list-group-flush">
                    <li id="top-partner-subscriptions" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/subscriptions">{{'Manage Subscriptions' | translate}}</a></li>
                    <li id="top-partner-expiring" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/expiring">{{'Expiring Entitlements' | translate}}</a></li>
                    <li id="top-partner-users" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/users">{{'Users' | translate}}</a></li>
                    <li id="top-partner-import" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/importusers">{{'Import Users' | translate}}</a></li>

                    <li id="top-partner-tokens" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/tokens">{{'Tokens' | translate}}</a></li>
                    <li id="top-partner-deals" class="list-group-item">
                        <a routerLink="/partner/deals">{{'Deals' | translate}}</a>
                    </li>
                    <li id="top-partner-quotes" class="list-group-item">
                        <a routerLink="/partner/quotes">{{'Quotes' | translate}}</a>
                    </li>
                    <li id="top-partner-customers" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/customers">{{'Customers' | translate}}</a></li>

                    <li id="top-partner-templates" *ngIf="IsPartnerAdmin" class="list-group-item"><a
                            routerLink="/partner/templates">{{'Email Templates' | translate}}</a></li>
                    <li id="top-partner-documents" class="list-group-item"><a
                            routerLink="/partner/documents">{{'Documents' | translate}}</a></li>
                    <li id="top-partner-documents" class="list-group-item"><a
                            routerLink="/partner/campaigns">{{'Campaigns' | translate}}</a></li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
        </div>
        <div class="col-12 col-sm-6 col-md-3">
        </div>
        <div class="col-12 col-sm-6 col-md-3" *ngIf="Notifications.length > 0">
            <div class="card top-card-menu">
                <div class="card-header">{{'Notifications' | translate}}</div>
                <div class="card-body">
                    <ul class="list-unstyled notifications">
                        <li *ngFor="let notification of Notifications">
                            <a routerLink="{{notification.Url}}">
                                <h5>{{notification.Title}}</h5>
                                <small>{{notification.Message}}</small>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>