<span class="close-button">
	<i class="fa fa-times" aria-hidden="true"></i>
</span>
<section id="organization-container" class="top-menu" [class.cl-light-theme]="IsLight" [class.cl-dark-theme]="IsDark">
	<div class="row">
		<div class="col-12 col-sm-6 col-md-2">
			<div class="card card-list top-card-menu">
				<ul class="list-group list-group-flush">
					<li id="top-organization-profile" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization">{{ 'Organization Options' | translate }}</a>
					</li>
					<li id="organization-payment-invoices" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/invoices">{{ 'Invoices' | translate }}</a>
					</li>
					<li id="organization-sales-tax-exemptions" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/salestaxexemptions/{{ User.OrganizationId }}">
							{{ 'Sales Tax Exemptions' | translate }}
						</a>
					</li>

					<li id="top-organization-subscriptions" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/subscriptions">{{ 'Content Subscriptions' | translate }}</a>
					</li>
					<li id="top-organization-feature-subscriptions" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/orgsubs">{{ 'Feature Subscriptions' | translate }}</a>
					</li>
					<li id="top-organization-users" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/users">{{ 'Users' | translate }}</a>
					</li>
					<li id="top-organization-content" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/content">{{ 'Content' | translate }}</a>
					</li>
					<li id="top-organization-categories" *ngIf="IsCustomContentCreator && !IsOrgAdmin" class="list-group-item">
						<a routerLink="/custom/categories/list">{{ 'Categories' | translate }}</a>
					</li>
					<li id="top-organization-courses" *ngIf="IsCustomContentCreator && !IsOrgAdmin" class="list-group-item">
						<a routerLink="/custom/courses/list">{{ 'Courses' | translate }}</a>
					</li>
					<li id="top-organization-assessments" *ngIf="IsCustomContentCreator && !IsOrgAdmin" class="list-group-item">
						<a routerLink="/custom/assessments/list">{{ 'Assessments' | translate }}</a>
					</li>
					<li id="top-organization-campaigns" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/campaigns">{{ 'Campaigns' | translate }}</a>
					</li>
					<li id="top-organization-playlists" *ngIf="IsOrgAdmin" class="list-group-item">
						<a routerLink="/organization/playlists/list">{{ 'Playlists' | translate }}</a>
					</li>
					<li id="top-organization-learning-path" *ngIf="IsCustomContentCreator || IsLearningPathAdmin" class="list-group-item">
						<a routerLink="/organization/gamification/jobs">{{ 'Learning Paths' | translate }}</a>
					</li>
					<li id="top-organization-content-approval" *ngIf="IsApprover" class="list-group-item">
						<a routerLink="/organization/contentapproval/list">{{ 'Content Approval' | translate }}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-4">
			<div class="card top-card-menu" *ngIf="IsCustomContentCreator">
				<div class="card-header">{{ 'Custom Courses' | translate }}</div>
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<ul class="list-unstyled">
								<li class="mb-3" *ngFor="let course of CustomCourses">
									<a routerLink="/custom/courses/edit/{{ course.Id }}" title="{{ course.Name }}">{{ course.Name }}</a>
								</li>
								<li class="mb-3">
									<a routerLink="/custom/courses/list" title="See All">
										<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;See All Courses</a
									>
								</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<button class="btn btn-orange" type="button" routerLink="/custom/courses/edit">Create</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3">
			<div class="card top-card-menu" *ngIf="IsCustomContentCreator">
				<div class="card-header">{{ 'Assessments' | translate }}</div>
				<div class="card-body">
					<ul class="list-unstyled">
						<li class="mb-3" *ngFor="let assessment of CustomAssessments">
							<a routerLink="/custom/assessments/edit/{{ assessment.Id }}" title="{{ assessment.Name }}">{{
								assessment.Name
							}}</a>
						</li>
						<li class="mb-3">
							<a routerLink="/custom/assessments/list" title="See All Assessments">
								<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;See All Assessments</a
							>
						</li>
					</ul>
					<div class="row">
						<div class="col">
							<button class="btn btn-orange" type="button" routerLink="/custom/assessments/new">Create</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3" *ngIf="Notifications.length > 0">
			<div class="card top-card-menu">
				<div class="card-header">Notifications</div>
				<div class="card-body">
					<ul class="list-unstyled notifications">
						<li *ngFor="let notification of Notifications">
							<a routerLink="{{ notification.Url }}">
								<h5>{{ notification.Title }}</h5>
								<small>{{ notification.Message }}</small>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
