import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermsOfServiceComponent } from './TermsOfService.component';

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: [TermsOfServiceComponent],
	exports: [TermsOfServiceComponent]
})
export class TermsOfServiceModule {}
