import { Component, ElementRef, Injectable, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { GetHostUrl } from '@app/utilities';
import { BaseComponent } from 'cadlearning.angular';
import { QButtonActionTypes, QMessageButtonDto, QMessageDto, QMessageTypes, QParticipantTypes } from 'cadlearning.dto';
import { ChatService } from './chat.service';

@Component({
	selector: 'chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
})
@Injectable()
export class ChatComponent extends BaseComponent<UserService> implements OnInit {
	@Input()
	public Expert: boolean = false;
	@Input()
	public InitialMessage?: string = undefined;

	@Input()
	public ProductId?: number = undefined;

	@Input()
	public VersionId?: number = undefined;

	@Input()
	public IsContent?: boolean = undefined;

	public Message?: string = undefined;

	public CurrentMessage?: QMessageDto = undefined;

	@ViewChild('chatHistory', { static: true }) private history: ElementRef = undefined!;

	constructor(public Chat: ChatService, injector: Injector) {
		super(injector);
	}

	async ngOnInit() {
		this.Chat.QBroadcast.subscribe((message) => {
			if (message.FromParticipant == null || message.FromParticipant.UserId !== this.User.Id) this.CurrentMessage = message;
			this.scrollToBottom();
		});

		if (!this.Expert) {
			var host = GetHostUrl(location.hostname);
			var siteName = 'CADLearning';
			if (host.endsWith('anhour.io')) {
				siteName = 'AnHour';
			} else {
				siteName = 'CADLearning';
			}

			let qmessage = {
				ButtonOnly: false,
				Buttons: [
					{
						Type: QButtonActionTypes.Response,
						Text: siteName + ' Support',
						Value: 'Support',
					},
					{
						Type: QButtonActionTypes.Response,
						Text: 'Product Specific',
						Value: 'Product Specific',
					},
				],
				Concepts: undefined,
				DateTime: new Date(),
				ConversationId: undefined,
				Entities: undefined,
				FromParticipant: undefined,
				Id: undefined!,
				InResponseToMessageId: undefined,
				InitialProductId: this.ProductId,
				InitialVersionId: this.VersionId,
				Intent: undefined,
				IntentScore: undefined,
				Lessons: undefined,
				Message:
					this.InitialMessage ||
					(this.User
						? `Hi, I’m ${siteName} Help 👋 You’ve got questions – I've got answers! 😉 <br /><br />Do you need <strong>${siteName} Support</strong> for help with things like updating your email, or do you have a question that’s <strong>Product Specific</strong>?”`
						: `Welcome to ${siteName} Assist. Please use the message box below to ask any support related question you might have. For more advanced assistance, please login.`),
				TimeStamp: undefined,
				ToParticipants: [
					{
						AccessToken: undefined!,
						Active: true,
						Name: this.User ? this.User.Name : 'Unlogged in User',
						Type: QParticipantTypes.User,
						UserId: this.User ? this.User.Id : undefined,
					},
				],
				Type: QMessageTypes.Message,
				isHTML: false,
				VerificationToken: undefined,
			} as QMessageDto;

			this.CurrentMessage = qmessage;
			this.Chat.SetDefaultMessage(qmessage);
		}
	}

	public ButtonClick(response: QMessageButtonDto, $event: Event) {
		$event.preventDefault();

		if (!this.CurrentMessage) return;

		if (this.CurrentMessage.Type === QMessageTypes.EscallationExpertRequest) {
			const accepted = (response.Value ?? '').toLowerCase().includes('yes');
			this.Chat.RespondToEscallationRequest({
				Accepted: accepted,
				ConversationId: this.Chat.ConversationId() ?? undefined!,
				UserId: this.User.Id,
				VerificationToken: this.CurrentMessage.VerificationToken ?? undefined!,
			});
		} else if (response.Type === QButtonActionTypes.OpenUrl) {
			window.open(response.Value, '_blank');
		} else {
			const message: QMessageDto = {
				ButtonOnly: false,
				ConversationId: this.CurrentMessage.ConversationId,
				DateTime: new Date(),
				Message: response.Value,
				Type:
					response.Type === QButtonActionTypes.Success
						? QMessageTypes.Success
						: response.Type === QButtonActionTypes.Fail
						? QMessageTypes.Fail
						: QMessageTypes.Response,
				InResponseToMessageId: this.CurrentMessage.Id,
				ToParticipants: [],
				FromParticipant: {
					UserId: this.User ? this.User.Id : undefined,
					Active: true,
					Type: this.Expert ? QParticipantTypes.Expert : QParticipantTypes.User,
					Name: this.User ? this.User.Name : 'Unlogged in User',
					AccessToken: this.UserService.GetAccessToken(),
				},
				isHTML: false,
				Id: undefined!,
			};
			this.Chat.ButtonResponse(message);
		}
		this.CurrentMessage = undefined;
	}

	public SendMessage($event: Event) {
		$event.preventDefault();

		if (!this.Message) return;

		this.CurrentMessage = undefined;

		if (this.Expert) {
			this.Chat.SendMessage({
				Id: undefined!,
				isHTML: false,
				ConversationId: this.Chat.ConversationId()!,
				InResponseToMessageId: this.Chat.QMessages.length > 0 ? this.Chat.QMessages[this.Chat.QMessages.length - 1].Id : undefined,
				Message: this.Message,
				FromParticipant: {
					AccessToken: this.User ? this.UserService.GetAccessToken() : null,
					Active: true,
					Name: this.User ? this.User.Name : 'Unlogged in User',
					Type: QParticipantTypes.Expert,
					UserId: this.User ? this.User.Id : undefined,
				},
				InitialProductId: this.ProductId,
				InitialVersionId: this.VersionId,
				ToParticipants: undefined,
				VerificationToken: undefined,
				Type: QMessageTypes.Message,
				DateTime: new Date(),
				TimeStamp: undefined,
				ButtonOnly: false,
				Buttons: undefined,
				Lessons: undefined,
				IsContent: this.IsContent,
			});
		} else {
			if (this.Chat.QMessages.some((m) => m.Type === QMessageTypes.Success || m.Type === QMessageTypes.Fail))
				this.Chat.ClearQConversation();

			this.Chat.SendMessage({
				Id: undefined!,
				isHTML: false,
				ConversationId: this.Chat.ConversationId()!,
				InResponseToMessageId: undefined,
				Message: this.Message,
				FromParticipant: {
					AccessToken: this.User ? this.UserService.GetAccessToken() : undefined,
					Active: true,
					Name: this.User ? this.User.Name : 'Unlogged in User',
					Type: QParticipantTypes.User,
					UserId: this.User ? this.User.Id : undefined,
					OrganizationId: this.User?.OrganizationId,
				},
				ToParticipants: undefined,
				VerificationToken: undefined,
				Type: !this.Chat.QMessages.some((m) => m.Type === QMessageTypes.InitialRequest)
					? QMessageTypes.InitialRequest
					: QMessageTypes.Message,
				DateTime: new Date(),
				TimeStamp: undefined,
				ButtonOnly: false,
				Buttons: undefined,
				Lessons: undefined,
				InitialProductId: this.ProductId,
				InitialVersionId: this.VersionId,
				IsContent: this.IsContent,
			});
		}

		this.Message = undefined;
	}

	private scrollToBottom() {
		try {
			let self = this;
			window.setTimeout(() => {
				self.history.nativeElement.scrollTop = self.history.nativeElement.scrollHeight;
			}, 300);
		} catch (err) {}
	}
}
