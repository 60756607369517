<div class="modal-header">
	<h4 class="modal-title">{{ 'SharePlaylist' | translate }}</h4>
</div>
<form novalidate [formGroup]="Form" (ngSubmit)="SaveAndClose()" *ngIf="!Loading">
	<div class="modal-body">
		<h4>{{ 'ExistingAcceptedInvites' | translate }}</h4>
		<div class="invitees">
			<div class="row" *ngFor="let invitee of Invitees">
				<div class="col">
					{{ invitee.User }}
				</div>
				<div class="col-auto">
					<button type="button" (click)="RemoveInvitee(invitee)" class="btn btn-danger"
						id="btnRemoveInvitee">X</button>
				</div>
			</div>
		</div>
		<h4>{{ 'AddNewInvitee' | translate }}</h4>
		<div class="form-group">
			<div class="row">
				<div class="col"
					[class.has-danger]="!Form.get('EmailAddress')!.valid && Form.get('EmailAddress')!.touched"
					[class.has-success]="Form.get('EmailAddress')!.valid">
					<input type="email" class="form-control" id="txtEmail" maxlength="450" placeholder="email address"
						formControlName="EmailAddress"
						[class.form-control-danger]="!Form.get('EmailAddress')!.valid && Form.get('EmailAddress')!.touched"
						[class.form-control-success]="Form.get('EmailAddress')!.valid" />
					<div *ngIf="Form.get('EmailAddress')!.errors && Form.get('EmailAddress')!.touched"
						class="alert alert-danger">
						<small>{{ 'PleaseEnterAValidEmailAddress' | translate }}</small>
					</div>
				</div>
				<div class="col-auto">
					<button type="button" (click)="AddInvitee()" class="btn btn-success" id="btnAddInvitee">{{ 'Add' |
						translate }}</button>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer text-right">
		<button type="submit" class="btn btn-orange" id="btnInvite">{{ 'Invite' | translate }}</button>
		<button type="button" class="btn btn-warning" id="btnCancel" (click)="Cancel()">{{ 'Cancel' | translate
			}}</button>
	</div>
</form>