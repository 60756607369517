<span class="close-button">
	<i class="fa fa-times" aria-hidden="true"></i>
</span>
<section id="users-container" class="top-menu" *ngIf="IsLoaded" [class.cl-light-theme]="IsLight"
	[class.cl-dark-theme]="IsDark">
	<div class="row">
		<div class="col-12 col-sm-6 col-md-2 user-menu-widget">
			<div class="card card-list top-card-menu">
				<ul class="list-group list-group-flush">
					<li id="top-users-home" class="list-group-item">
						<a routerLink="/">Home</a>
					</li>
					<li id="top-users-profile" class="list-group-item">
						<a routerLink="/user/profile">{{ 'Profile' | translate }}</a>
					</li>
					<li id="top-users-addresses" class="list-group-item">
						<a routerLink="/user/addresses">{{ 'Addresses' | translate }}</a>
					</li>
					<li id="top-users-payment-methods" class="list-group-item">
						<a routerLink="/user/paymentmethods">{{ 'PaymentMethods' | translate }}</a>
					</li>
					<li id="top-users-subscriptions" class="list-group-item">
						<a routerLink="/user/subscriptions">{{ 'Subscriptions' | translate }}</a>
					</li>
					<li id="top-users-sales-tax-exemptions" class="list-group-item">
						<a routerLink="/user/salestaxexemptions/{{ User.Id }}">{{ 'Sales Tax Exemptions' | translate
							}}</a>
					</li>
					<li id="top-users-transcripts" class="list-group-item">
						<a routerLink="/user/transcripts">{{ 'Transcripts' | translate }}</a>
					</li>
					<li id="top-users-reset-password" class="list-group-item">
						<a routerLink="/user/resetpassword">{{ 'ResetPassword' | translate }}</a>
					</li>
					<li id="top-users-redeem-token" class="list-group-item">
						<a routerLink="/user/redeemtoken">{{ 'RedeemToken' | translate }}</a>
					</li>
					<li id="top-users-permissions" class="list-group-item">
						<a routerLink="/user/permissions">{{ 'Permissions' | translate }}</a>
					</li>
					<li id="top-users-notifications" class="list-group-item">
						<a routerLink="/user/notificationpreferences">{{ 'NotificationPreferences' | translate }}</a>
					</li>
					<li id="top-users-logout" class="list-group-item">
						<span (click)="Logout($event)">
							<i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;{{ 'LogOut' | translate }}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-4 user-menu-widget">
			<div class="card top-card-menu" *ngIf="RecentActivity !== null">
				<div class="card-header">
					{{ 'RecentActivity' | translate }}
				</div>
				<div class="card-body">
					<p class="text-info" *ngIf="!RecentActivity.length">No Activity, please watch some videos!</p>
					<ul id="user-activity" class="list-unstyled" *ngIf="RecentActivity.length">
						<li *ngFor="let Activity of RecentActivity">
							<div class="row">
								<div class="col-auto align-self-center">
									<a routerLink="/lesson/{{ Activity.Id }}">
										<i class="fa fa-play-circle play"></i>
									</a>
								</div>
								<div class="col">
									<a routerLink="/lesson/{{ Activity.Id }}">{{ Activity.Name }}</a>
									<br />
									<ul class="list-inline comma-separated">
										<li class="list-inline-item" *ngFor="let Course of Activity.Courses">
											<a routerLink="/course/{{ Course.Id }}" title="{{ Course.Name }}">{{
												Course.Name }}</a>
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3 user-menu-widget">
			<div class="card top-card-menu" *ngIf="!!TestedOutOfAchivements">
				<div class="card-header">
					{{ 'AchievementsTestedOutOf' | translate }}
				</div>
				<div class="card-body">
					<p class="text-info"
						*ngIf="!TestedOutOfAchivements.Badges && !TestedOutOfAchivements.Medallions && !TestedOutOfAchivements.Disciplines">
						{{'YouHaventTestedOutOfAnyAchievementsYetTakeSomeAssessmentsAndGetGoing' | translate}}</p>
					<div *ngIf="TestedOutOfAchivements.Badges.length">
						<p>{{'Badges' | translate }}</p>
						<ul id="user-activity" class="list-unstyled">
							<li *ngFor="let Badges of TestedOutOfAchivements.Badges">
								<a routerLink="/goals/badge/{{Badges.Id}}">{{Badges.Name}}</a>
							</li>
						</ul>
					</div>
					<div *ngIf="TestedOutOfAchivements.Medallions.length">
						<p>{{'Medallions' | translate }}</p>
						<ul id="user-activity" class="list-unstyled">
							<li *ngFor="let Medallions of TestedOutOfAchivements.Medallions">
								<a routerLink="/goals/medallion/{{Medallions.Id}}">{{Medallions.Name}}</a>
							</li>
						</ul>
					</div>
					<div *ngIf="TestedOutOfAchivements.Disciplines.length">
						<p>{{'Goals' | translate }}</p>
						<ul id="user-activity" class="list-unstyled">
							<li *ngFor="let Disciplines of TestedOutOfAchivements.Disciplines">
								<a routerLink="/goals/goal/{{Disciplines.Id}}">{{Disciplines.Name}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3 user-menu-widget">
			<div class="vertical-group" [class.flex-column]="Organizations.length > 1"
				[class.vertical-fill]="Notifications.length == 0">
				<div class="card organization-list top-card-menu" *ngIf="Organizations.length > 1">
					<div class="card-header">{{ 'YourOrganizations' | translate }}</div>
					<div class="card-body">
						<ul class="list-unstyled">
							<li *ngFor="let organization of Organizations">
								<a href="#" (click)="SelectOrganization(organization, $event)">{{ organization.Name }}
									<span class="fa fa-check"
										*ngIf="User && User.OrganizationId == organization.Id"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card top-card-menu user-menu-widget" [class.vertical-fill]="Organizations.length <= 1"
					*ngIf="Notifications.length > 0">
					<div class="card-header">{{ 'Notifications' | translate }}</div>
					<div class="card-body">
						<ul class="list-unstyled notifications">
							<li *ngFor="let notification of Notifications">
								<a routerLink="{{ notification.Url }}">
									<h5>{{ notification.Title }}</h5>
									<small>{{ notification.Message }}</small>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-3 user-menu-widget">
			<div class="vertical-group" [class.flex-column]="Projects.length >= 1">
				<div class="card organization-list top-card-menu" *ngIf="Projects.length >= 1">
					<div class="card-header">{{ 'YourProjects' | translate }}</div>
					<div class="card-body">
						<ul class="list-unstyled">
							<li *ngFor="let project of Projects">
								<a href="#" (click)="SelectProject(project, $event)">{{ project.Name }}
									<span class="fa fa-check" *ngIf="User && User.ProjectId == project.Id"></span>
								</a>
							</li>
							<li>
								<a href="#" (click)="SelectProject(undefined, $event)">{{ 'NoProjectSelected' |
									translate }}
									<span class="fa fa-check" *ngIf="User && User.ProjectId == null"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<span class="logout" id="btnLogout" (click)="Logout($event)">
		<i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;{{ 'LogOut' | translate }}
	</span>
	<span *ngIf="IsLoaded" id="q-availability" (click)="AvailableClicked($event)">
		<label for="chkEscallationAvailability">
			<kendo-switch class="no-close" [(ngModel)]="UserDto.AvailableForEscallation"
				(ngModelChange)="UpdateAvailability()"></kendo-switch>
			{{ 'AvailableToHelpOthers' | translate }}
		</label>
	</span>
</section>
