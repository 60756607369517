import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/directives/directives.module';
import { OdataComboBoxComponent } from '@app/shared/odatacombobox/odatacombobox.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownFilterComponent } from './filters/DropDownFilter/DropDownFilter.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, InputsModule, DropDownsModule, LayoutModule, DirectivesModule],
    declarations: [OdataComboBoxComponent, DropDownFilterComponent],
    exports: [OdataComboBoxComponent, DropDownFilterComponent]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
