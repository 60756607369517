<div id="custom-content-side">
	<div class="bg-primary side-bar-header">
		<a href="#" (click)="Close($event)" class="close pull-right" id="btnClosePlaylist" style="font-size:20px">
			<i class="fa-solid fa-square-xmark"></i>
		</a>
		<h5>{{ 'Content' | translate }}</h5>
	</div>
	<div class="side-bar-content" *ngIf="IsLoaded">
		<a href="#" id="new-course" class="title" routerLink="/custom/courses/edit" (click)="Closed.emit()">
			<i class="fa fa-plus" aria-hidden="true"></i>&nbsp;{{ 'NewCourse' | translate }}</a>
		<kendo-panelbar [expandMode]="'single'" (stateChange)="PanelStateChange($event)">
			<kendo-panelbar-item *ngFor="let course of CustomCourses" id="{{ 'CustomCourses' + course.Id }}" title="">
				<ng-template kendoPanelBarItemTitle>
					<span class="panel-title">
						<a routerLink="/custom/courses/edit/{{ course.Id }}" title="Edit Custom Course"><i
								class="fas fa-edit"></i></a>&nbsp;{{ course.Name }}
					</span>
				</ng-template>
				<ng-template kendoPanelBarContent>
					<kendo-treeview [nodes]="CourseStructureMap.get(course.Id)" [childrenField]="'ChildItems'"
						[hasChildren]="HasChildren" [isExpanded]="IsExpanded" (collapse)="HandleCollapse($event)"
						(expand)="HandleExpand($event)" (nodeClick)="ToggleExpand($event)" kendoTreeViewExpandable
						kendoTreeViewHierarchyBinding>
						<ng-template kendoTreeViewNodeTemplate let-dataItem let-node>
							<div *ngIf="dataItem.ItemType == 2" class="row align-items-center">
								<div class="col">
									<h6>{{ dataItem.Name }}</h6>
								</div>
							</div>
							<div *ngIf="dataItem.ItemType == 3" class="row align-items-center"
								[ngClass]="{ 'move-left': IsRootElement(dataItem, course.Id) }">
								<div class="col">
									<a class="row" *ngIf="dataItem.IsOwnContent"
										routerLink="/custom/lessons/edit/{{ dataItem.ItemId }}"
										[queryParams]="{ CourseId: course.Id }">
										<div class="col-auto play">
											<i class="far fa-play-circle play"></i>
										</div>
										<div class="col">
											{{ dataItem.Name }}
										</div>
									</a>
									<a class="row" *ngIf="!dataItem.IsOwnContent"
										routerLink="/lesson/{{ dataItem.ItemId }}"
										[queryParams]="{ courseId: course.Id }" [hidden]="dataItem.ItemType != 3">
										<div class="col-auto play">
											<i class="far fa-play-circle play"></i>
										</div>
										<div class="col">
											{{ dataItem.Name }}
										</div>
									</a>
								</div>
							</div>
							<div *ngIf="dataItem.ItemType == 7" class="row align-items-center"
								[ngClass]="{ 'move-left': IsRootElement(dataItem, course.Id) }">
								<div class="col">
									<a href="{{ dataItem.Name }}" *ngIf="dataItem.ResourceType == 8" target="_blank"
										(click)="DownloadStructureResource(dataItem, $event)">
										<i class="fa fa-external-link" aria-hidden="true"></i> {{ dataItem.Description
										}}</a>
									<a *ngIf="dataItem.ResourceType != 8"
										(click)="DownloadStructureResource(dataItem, $event)">
										<i class="fa fa-external-link" aria-hidden="true"></i> {{ dataItem.Description
										}}</a>
								</div>
							</div>
						</ng-template>
					</kendo-treeview>
				</ng-template>
			</kendo-panelbar-item>
		</kendo-panelbar>
	</div>
</div>