import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AcceptTermsComponent } from '@app/account/acceptterms/acceptterms.component';
import { UserService } from '@app/authentication/user.service';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { UsersV1Client } from 'cadlearning.angular';

@Injectable()
export class AcceptTermsGuard implements CanActivate {
	constructor(private router: Router, private dialogService: DialogService, private userService: UserService, private userClient: UsersV1Client) { }

	public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log(route + '' + state);
		console.log('AuthorizationGuard, canActivate');

		const isAuthorized = this.userService.User != null;

		console.log('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);

		if (!isAuthorized) return true;

		//console.log('Has Accepted Terms', this.userService.User.HasAcceptedTerms);
		//let isAccepted = await this.userClient.HasAcceptedTerms();
		//console.log('Has Accepted Terms 2', isAccepted);
		if (this.userService.User.HasAcceptedTerms)
			//if (isAccepted.AcceptedTerms)
			//This should be all inclusive and return true if any of the criteria are not met.
			return true;

		const width = Math.min(920, window.innerWidth - 100);
		const height = Math.min(600, window.innerHeight - 100);

		const windowRef = this.dialogService.open({
			title: 'Accept Terms of Service',
			content: AcceptTermsComponent,
			width: width,
			height: height,
			actions: [{ text: 'Accept', primary: true }, { text: 'Reject' }],
			minWidth: width,
		});

		let promise = new Promise<boolean>((resolve, reject) => {
			windowRef.result.subscribe(async (result: any) => {
				if (result instanceof DialogCloseResult || result.text !== 'Accept') {
					await this.userService.Logout();
					this.router.navigateByUrl('/');
					resolve(false);
				} else {
					await this.userService.AcceptTerms();
					resolve(true);
				}
			});
		});

		return promise;
	}
}
