import { Component, Injectable, Injector, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { DEFAULT_LOGIN_REDIRECT_PATH } from '@app/constants';
import { BaseComponent } from 'cadlearning.angular';

@Component({
	selector: 'accept-terms',
	templateUrl: './acceptterms.component.html',
	styleUrls: ['./acceptterms.component.scss'],
})
@Injectable()
export class AcceptTermsComponent extends BaseComponent<UserService> implements OnInit {
	public Accepting: boolean = false;
	public ReturnUrl: string = null;

	constructor(private titleService: Title, private metaService: Meta, private router: Router, injector: Injector) {
		super(injector);
	}

	async ngOnInit() {
		this.titleService.setTitle('CADLearning - Accept Terms of Service');
		this.metaService.updateTag({ name: 'description', content: '' });
		this.metaService.updateTag({ name: 'keywords', content: '' });
	}

	public async Accept() {
		this.Accepting = true;

		this.ReturnUrl = this.Route.snapshot.queryParams.rurl;
		try {
			await this.UserService.AcceptTerms();

			if (!this.ReturnUrl) {
				this.router.navigate([DEFAULT_LOGIN_REDIRECT_PATH]);
			} else {
				this.Route.snapshot.queryParams.rurl = null;
				this.router.navigateByUrl(decodeURIComponent(this.ReturnUrl));
			}
		} finally {
			this.Accepting = false;
		}
	}

	public async close(status) {
		if (status === 'accept') {
			await this.Accept();
		}
	}
}
