import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnumTranslatePipe } from './enum-translation-pipe.component';
import { EventCastPipe } from './event-cast.pipe';
import { FormGroupCastPipe } from './formgroup-cast.pipe';

@NgModule({
    declarations: [EnumTranslatePipe, FormGroupCastPipe, EventCastPipe],
    imports: [],
    exports: [EnumTranslatePipe, FormGroupCastPipe, EventCastPipe]
})
export class PipesModule {
	static forRoot(): ModuleWithProviders<PipesModule> {
		return {
			ngModule: PipesModule,
			providers: []
		};
	}
}
