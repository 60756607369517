import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

//Lazy loading compatibility
(<any>window).browser = null;

if (environment.production) enableProdMode();

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
