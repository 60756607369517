import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UserService } from '@app/authentication/user.service';
import { API_URL, IS_DARK_THEME, IS_LIGHT_THEME } from '@app/constants';
import { BaseComponent, UsersV1Client } from 'cadlearning.angular';
import { UserSuggestedProductsDto } from 'cadlearning.dto';

@Component({
	selector: 'library',
	templateUrl: './library.component.html',
	styleUrls: ['./topmenu.component.scss', './topmenumobile.component.scss'],
})
export class LibraryComponent extends BaseComponent<UserService> implements OnInit {
	public SuggestedProducts: UserSuggestedProductsDto[] = [];
	public AllSuggestedProducts: UserSuggestedProductsDto[] = [];

	public Expanded = false;
	public IsLibraryLoaded = false;

	constructor(
		private userClient: UsersV1Client,
		@Inject(IS_DARK_THEME) public IsDark: boolean,
		@Inject(IS_LIGHT_THEME) public IsLight: boolean,
		@Inject(API_URL) private apiUrl: string,
		injector: Injector
	) {
		super(injector);
		super.OverrideTranslationUri('/library/topmenu');
	}

	async ngOnInit() {
		await this.TranslatePage();
		this.IsLoaded = true;

		if (!this.User) {
			this.AllSuggestedProducts = await this.userClient.GetPublicSuggestedProducts();
		} else {
			this.AllSuggestedProducts = await this.userClient.GetSuggestedProducts();
		}
		this.ReturnSuggestedProducts();

		this.IsLibraryLoaded = true;
	}

	public GetRssFeedUrl() {
		return this.apiUrl + '/products/changesfeed?portalUrl=' + encodeURIComponent(window.location.hostname);
	}

	public ToggleExpand($event: Event) {
		this.Expanded = !this.Expanded;
		this.ReturnSuggestedProducts();
		$event.preventDefault();
	}

	public FixProductImageUrl(product: UserSuggestedProductsDto, $event: Event) {
		(<any>$event.srcElement).src = '/img/products/product_000001.png';
		product['Name'] = product.Product;
	}

	private ReturnSuggestedProducts() {
		if (this.Expanded || !this.User || !this.AllSuggestedProducts.some((p) => p.Owned)) {
			this.SuggestedProducts = this.AllSuggestedProducts;
		} else {
			this.SuggestedProducts = this.AllSuggestedProducts.filter((p) => p.Owned);
		}
	}
}
