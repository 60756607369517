declare var ga: any;
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from '@app/shared/shared.module';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
// i18n support
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartModule } from '@progress/kendo-angular-charts';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import {
	AssessmentsV1Client,
	AzureModule,
	BadgesV1Client,
	BlogArticlesV1Client,
	BlogsV1Client,
	CampaignDripsV1Client,
	CampaignsV1Client,
	CLIENT_CONFIG,
	ConfirmModule,
	CoursesV1Client,
	DisciplinesV1Client,
	DocumentsV1Client,
	DomainsV1Client,
	EntitlementsV1Client,
	IClientService,
	IndustriesV1Client,
	InventoryV1Client,
	InvoicesV1Client,
	LessonsV1Client,
	MedallionsV1Client,
	OfflineV1Client,
	OrganizationsV1Client,
	PaymentMethodsV1Client,
	PaymentProvidersV1Client,
	PlaylistsV1Client,
	PreferencesV1Client,
	PriceListRevisionsV1Client,
	PriceListsV1Client,
	ProductCategoriesV1Client,
	ProductsV1Client,
	ProjectsV1Client,
	PublicVideosV1Client,
	QuestionsV1Client,
	ReportsV1Client,
	ResourcesV1Client,
	SalesTaxesV1Client,
	SearchV1Client,
	ShippingMethodsV1Client,
	SupportV1Client,
	SystemV1Client,
	TokensV1Client,
	TopicsV1Client,
	UsersV1Client,
	WorkflowsV1Client
} from 'cadlearning.angular';
import 'hammerjs';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';
import { AcceptTermsComponent } from './account/acceptterms/acceptterms.component';
import { AppComponent } from './app/app.component';
import { AuthConfigModule } from './authentication/auth-config.module';
import { API_URL, APP_INSIGHTS, CLIENT_ID, DEFAULT_PERFECT_SCROLLBAR_CONFIG } from './constants';
import { DialogsModule } from './dialogs/dialogs.module';
import { DirectivesModule } from './directives/directives.module';
import { AcceptTermsGuard } from './guards/AcceptTermsGuard';
import { CanActivatePartnerSalesGuard } from './guards/CanActivatePartnerSalesGuard';
import { CanActivateOrganizationAdminGuard } from './guards/CanActiviateOrganizationAdminGuard';
import { CanActivateOrganizationContentCreatorGuard } from './guards/CanActiviateOrganizationContentCreatorGuard';
import { CanActivateOrganizationGuard } from './guards/CanActiviateOrganizationGuard';
import { CanActivateOrganizationReportingGuard } from './guards/CanActiviateOrganizationReportingGuard';
import { CanActivatePartnerGuard } from './guards/CanActiviatePartnerGuard';
import { CanActivateUserGuard } from './guards/CanActiviateUserGuard';
import { CanActivateBotGuard, CanActivateProjectGuard } from './guards/OrganizationFeatureGuards';
import { BurnDownComponent } from './home/dialogs/burndown.component';
import { TermsOfServiceModule } from './legal/TermsOfService/TermsOfService.module';
import { ChatComponent } from './main/chat/chat.component';
import { ChatModule, ChatService } from './main/chat/chat.service';
import { CustomContentMenuComponent } from './main/custom/customcontent.component';
import { ExpertMenuComponent } from './main/expert/expert.component';
import { PlayListMenuComponent } from './main/playlist/playlist.component';
import { PluginMenuComponent } from './main/plugin/plugin.component';
import { QMenuComponent } from './main/q/q.component';
import { LibraryComponent } from './main/topmenu/library.component';
import { OrganizationsComponent } from './main/topmenu/organizations.component';
import { PartnersComponent } from './main/topmenu/partners.component';
import { ProjectsComponent } from './main/topmenu/projects.component';
import { ReportsComponent } from './main/topmenu/reports.component';
import { UsersComponent } from './main/topmenu/users.component';
import { YourPathComponent } from './main/yourpath/yourpath.component';
//Pipes
import { PipesModule } from './pipes/pipes.module';
import { SchedulerComponent } from './reports/scheduler/scheduler.component';
import { ErrorHandlerService } from './Services/ErrorHandler.service';
import { SignalRModule, SignalRService } from './SignalR/SignalRService';
import { WebApiTranslationLoaderComponent } from './WebApiTranslationLoader.component';

@NgModule({
	declarations: [
		YourPathComponent,
		AppComponent,
		LibraryComponent,
		PartnersComponent,
		OrganizationsComponent,
		UsersComponent,
		PlayListMenuComponent,
		CustomContentMenuComponent,
		PluginMenuComponent,
		QMenuComponent,
		ExpertMenuComponent,
		ChatComponent,
		WebApiTranslationLoaderComponent,
		ProjectsComponent,
		AcceptTermsComponent,
		BurnDownComponent,
		SchedulerComponent,
		ReportsComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		DialogModule,
		WindowModule,
		ChartModule,
		ConfirmModule.forRoot(),
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: environment.production,
		}),
		SharedModule.forRoot(),
		NgbDropdownModule,
		AzureModule.forRoot(),
		DirectivesModule.forRoot(),
		PipesModule.forRoot(),
		DialogsModule.forRoot(),
		SortableModule,
		DropDownsModule,
		LayoutModule,
		ButtonsModule,
		InputsModule,
		PopupModule,
		NotificationModule,
		TreeViewModule,
		SignalRModule.forRoot(),
		ChatModule.forRoot(),
		QuillModule.forRoot(),
		PerfectScrollbarModule,
		TermsOfServiceModule,
		// i18n support
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebApiTranslationLoaderComponent,
			},
		}),
		AuthConfigModule,
		RouterModule.forRoot(
			[
				{ path: 'free30daytrial', redirectTo: '/account/trial' },
				{
					path: 'downloads',
					loadChildren: () => import('./downloads/downloads.module').then((m) => m.DownloadsModule),
				},
				{
					path: 'contact',
					loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
				},
				{
					path: 'legal',
					loadChildren: () => import('./legal/legal.module').then((m) => m.LegalModule),
				},
				{
					path: 'product',
					loadChildren: () => import('./product/product.module').then((m) => m.ProductModule),
					canActivate: [AcceptTermsGuard],
				},
				{
					path: 'lesson',
					loadChildren: () => import('./lesson/lesson.module').then((m) => m.LessonModule),
					canActivate: [AcceptTermsGuard],
				},
				{
					path: 'playback',
					loadChildren: () => import('./playback/playback.module').then((m) => m.PlaybackModule),
				},
				{
					path: 'search',
					loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
				},
				{
					path: 'account',
					loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
				},
				{
					path: 'user',
					loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
					canActivate: [AcceptTermsGuard, CanActivateUserGuard],
				},
				{
					path: 'organization',
					loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
					canActivate: [AcceptTermsGuard, CanActivateOrganizationGuard],
				},
				{
					path: 'custom',
					loadChildren: () => import('./custom/custom.module').then((m) => m.CustomContentModule),
					canActivate: [AcceptTermsGuard, CanActivateOrganizationContentCreatorGuard],
				},
				{
					path: 'partner',
					loadChildren: () => import('./partner/partner.module').then((m) => m.PartnerModule),
					canActivate: [AcceptTermsGuard, CanActivatePartnerSalesGuard],
				},
				{
					path: 'reports',
					loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
					canActivate: [AcceptTermsGuard, CanActivateOrganizationGuard],
				},
				{
					path: 'assessments',
					loadChildren: () => import('./assessments/assessments.module').then((m) => m.AssessmentsModule),
					canActivate: [CanActivateUserGuard],
				},
				{
					path: 'support',
					loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
				},
				{
					path: 'about',
					loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
				},
				{
					path: 'projects',
					loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
					canActivate: [AcceptTermsGuard, CanActivateProjectGuard],
				},
				{
					path: 'expert',
					loadChildren: () => import('./expert/expert.module').then((m) => m.ExpertModule),
				},
				{
					path: 'goals',
					loadChildren: () => import('./goals/goals.module').then((m) => m.GoalsModule),
				},
				{
					path: 'workflow',
					loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule),
				},
				{
					path: '',
					pathMatch: 'full',
					loadChildren: () => import('./home/accounthome.module').then((m) => m.AccountHomeModule),
					canActivate: [AcceptTermsGuard, CanActivateUserGuard],
				},
				{
					path: 'subscribe',
					redirectTo: 'account',
				},
				{
					path: 'eventtrial',
					redirectTo: '/account/trial?sku=CL-TRIAL-30DAY&partnerId=100982&orgid=100982&referralCode=1',
				},
			],
			{
				anchorScrolling: 'enabled',
				onSameUrlNavigation: 'reload',
				scrollPositionRestoration: 'enabled',
				initialNavigation: 'disabled',
				enableTracing: !environment.production,
				relativeLinkResolution: 'legacy',
			}
		),
		NotificationModule,
	],
	providers: [
		{
			provide: CLIENT_CONFIG,
			useFactory: AppModule.GetClientConfig,
			deps: [API_URL, ErrorHandlerService],
			multi: false,
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: ErrorHandler,
			useExisting: ErrorHandlerService,
		},
		CanActivateOrganizationContentCreatorGuard,
		CanActivateOrganizationGuard,
		CanActivateOrganizationAdminGuard,
		CanActivateOrganizationReportingGuard,
		CanActivatePartnerGuard,
		CanActivatePartnerSalesGuard,
		CanActivateUserGuard,
		CanActivateBotGuard,
		CanActivateProjectGuard,
		AcceptTermsGuard,
		TranslateModule,
		ChatService,
		SignalRService,
		AssessmentsV1Client,
		BadgesV1Client,
		BlogArticlesV1Client,
		BlogsV1Client,
		CampaignDripsV1Client,
		CampaignsV1Client,
		CoursesV1Client,
		DisciplinesV1Client,
		DocumentsV1Client,
		DomainsV1Client,
		EntitlementsV1Client,
		IndustriesV1Client,
		InventoryV1Client,
		InvoicesV1Client,
		LessonsV1Client,
		MedallionsV1Client,
		OfflineV1Client,
		OrganizationsV1Client,
		PaymentMethodsV1Client,
		PaymentProvidersV1Client,
		PlaylistsV1Client,
		PriceListRevisionsV1Client,
		PriceListsV1Client,
		ProductCategoriesV1Client,
		ProductsV1Client,
		ProjectsV1Client,
		PublicVideosV1Client,
		PreferencesV1Client,
		QuestionsV1Client,
		ReportsV1Client,
		ResourcesV1Client,
		SalesTaxesV1Client,
		SearchV1Client,
		ShippingMethodsV1Client,
		SupportV1Client,
		SystemV1Client,
		TokensV1Client,
		TopicsV1Client,
		UsersV1Client,
		WorkflowsV1Client,
		Title,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(@Inject(APP_INSIGHTS) appInsights: ApplicationInsights) {
		appInsights.loadAppInsights();
	}

	static GetClientConfig(apiUrl: string, errorService: ErrorHandlerService): IClientService {
		const result = {
			ClientId: CLIENT_ID,
			ServiceUrl: apiUrl,
			ErrorService: errorService,
		};

		return result;
	}

	static CreateTranslateLoader(http: HttpClient, baseHref: string) {
		// Temporary Azure hack
		if (baseHref === null && typeof window !== 'undefined') baseHref = window.location.origin;

		// i18n files are in `wwwroot/assets/`
		return new TranslateHttpLoader(http, `${baseHref}/assets/i18n/`, '.json');
	}
}
