import { Component } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { of } from 'rxjs';

@Component({
	template: ''
})
export class WebApiTranslationLoaderComponent implements TranslateLoader {
	constructor() { }
	public getTranslation(lang: string, prefix: string = 'translations/'): any {
		/* This is actually just a dummy so the default http loader isn't trying to make requests to the /assets folder */
		return of({});
	}
}
