import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/authentication/user.service';
import { BadgesV1Client, BaseComponent, UsersV1Client } from 'cadlearning.angular';
import { BadgeLearningPathDto } from 'cadlearning.dto';

@Component({
	selector: 'yourpath-menu',
	templateUrl: './yourpath.component.html',
	styleUrls: ['./yourpath.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					//opacity: 1
					height: '*',
				})
			),
			state(
				'closed',
				style({
					//opacity: 0,
					height: '0px',
				})
			),
			transition('open => closed', [animate(150)]),
			transition('closed => open', [animate(150)]),
		]),
	],
})
export class YourPathComponent extends BaseComponent<UserService> implements OnInit {
	@Output() Closed = new EventEmitter();
	public Loaded = false;
	public LearningPath: BadgeLearningPathDto[] = [];

	public ScrollPosition = 0;

	private STORAGE_KEY = 'YourPathData';

	constructor(private userClient: UsersV1Client, private badgeClient: BadgesV1Client, private router: Router, public injector: Injector) {
		super(injector);

		super.OverrideTranslationUri('/appmain');
	}

	async ngOnInit() {
		await this.TranslatePage();

		this.LearningPath = await this.userClient.YourPath(this.User.Id);
		let hasExpanded = false;

		const oldExpandedBadges: number[] = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY) || '[]');

		let expandedBadges: number[] = [];
		this.LearningPath.forEach((lp) => {
			if ((lp.PercentComplete < 100 && !hasExpanded) || oldExpandedBadges.some((b) => b === lp.Id)) {
				hasExpanded = true;
				lp['Expanded'] = true;
				expandedBadges.push(lp.Id);
			} else {
				lp['Expanded'] = false;
			}
		});

		sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(expandedBadges));

		this.Loaded = true;

		this.SetPosition();
	}

	private SetPosition() {
		setTimeout(() => {
			if (!sessionStorage.getItem('YourPathPosition')) {
				const badge = this.LearningPath.find((p) => p.Expanded);
				if (badge) {
					let element = document.getElementById(`yourpath-badge-${badge.Id}`);
					if (element)
						this.ScrollPosition = element.offsetTop;
				}
			} else {
				let storageElement = sessionStorage.getItem('YourPathPosition');
				if (storageElement)
					this.ScrollPosition = parseInt(storageElement);
			}
		});
	}

	public LessonClicked() {
		this.Closed.emit();
	}

	public async Assess(badge: BadgeLearningPathDto) {
		const assessmentId = await this.badgeClient.GetAchievementAssessment(this.User.Id, badge.Id);
		this.router.navigateByUrl(`/assessments/takeassessment?AssessmentId=${assessmentId}`);
	}

	public SideBarScrolled($event: Event) {
		if (!this.Loaded) return;

		sessionStorage.setItem('YourPathPosition', (<any>$event.srcElement).scrollTop.toString());
	}

	public Close($event: Event) {
		$event.preventDefault();
		this.Closed.emit();
	}

	public ToggleLearningPathExpand(badge: BadgeLearningPathDto) {
		badge['Expanded'] = !badge['Expanded'];

		let expandedBadges: number[] = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY) || '[]');

		if (!badge['Expanded']) {
			if (expandedBadges.some((b) => b === badge.Id)) expandedBadges.slice(expandedBadges.indexOf(badge.Id));
		} else {
			if (!expandedBadges.some((b) => b === badge.Id)) expandedBadges.push(badge.Id);
		}

		sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(expandedBadges));
	}
}
