import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/authentication/user.service';

@Injectable()
export class CanActivateUserGuard implements CanActivate {
	constructor(private userService: UserService) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log(route + '' + state);
		console.log('AuthorizationGuard, canActivate');

		const isAuthorized = this.userService.User != null;

		console.log('AuthorizationGuard, canActivate isAuthorized: ' + isAuthorized);

		if (isAuthorized) return true;

		let returnUrl = state.url;
		if (returnUrl.indexOf('#') >= 0) returnUrl = returnUrl.substr(0, returnUrl.indexOf('#'));

		await this.userService.Login(returnUrl);
		return false;
	}
}
