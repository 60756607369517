<h3>Terms of Service</h3>
<p>
	4D Technologies, LLC (dba CADLearning) (“4D Technologies”, “we”, “us”, “our(s)”) is an online learning platform where users can access
	on-demand, customizable learning systems designed to identify and address knowledge gaps and provide in-application support to maximize
	performance for users of Autodesk 2D and 3D design, engineering and entertainment software
</p>
<p>PLEASE READ THESE TERMS OF SERVICE</p>
<ul>
	<li>Our (“TOS”)</li>
	<li>OUR <a [routerLink]="['./']" fragment="privacy-policy">PRIVACY POLICY</a>,</li>
	<li><a [routerLink]="['./']" fragment="acceptable-use-policy">ACCEPTABLE USE POLICY</a> (“AUP”),</li>
	<li><a [routerLink]="['./']" fragment="return-shipping-policy">PRODUCT REFUND, RETURN AND SHIPPING POLICY</a>,</li>
	<li>AND ALL OTHER 4D TECHNOLOGIES POLICIES CAREFULLY.</li>
</ul>
<p>
	THESE ARE LEGALLY BINDING DOCUMENTS THAT CONTROL YOUR ACCESS TO AND USE OF THE 4D SERVICES (AS DEFINED HEREIN) AND AFFECT YOUR RIGHTS
	AND OBLIGATIONS.
</p>
<ol>
	<li>
		ACCEPTANCE OF TERMS. By using the 4D Services, you acknowledge and agree to the terms and conditions contained herein. Such
		acknowledgement and agreement is further confirmed by indicating your acceptance as applicable. The 4D Services, including any
		updates, enhancements, new features, and/or the addition of any new websites, that 4D provides to you are subject to these TOS. We
		reserve the right to update these TOS at any time by posting such changes on the website through which you are accessing the 4D
		Services. All such changes will be effective from the date on which they are posted and your continued use of the 4D Services
		thereafter serves as your acceptance of such changes. You should check these TOS from time to time to read what changes have been
		made.
	</li>

	<li>
		DESCRIPTION OF THE 4D SERVICES AND PRODCUTS. Through our websites (located at CADLearning.com or as otherwise hosted by us,
		collectively the “Website”), we provide you with access to a variety of products, including s and eBooks (the “Products”), as well
		as resources and services, including subscription and non-subscription Websites, any associated subdomains, instructive courses,
		download areas, communication forums, product information, Software (as defined herein), Service Materials (as defined herein)
		and/or other related content and services (collectively the “4D Services”).
	</li>

	<li>
		PAYING FOR YOUR SUBSCRIPTION. When you sign up for an Individual Membership, you are subscribing to certain 4D Services. You may
		sign up for either one-month or one-year Subscription terms for access to and use of the 4D Services (your “Subscription”). Yearly
		Subscriptions may be paid in full up-front or in monthly installments, monthly subscriptions are paid on a monthly basis (note that
		all student and faculty subscriptions fees are charged in a single yearly installment). The applicable fees and any associated taxes
		for Subscription will be billed immediately to your credit card or other form of payment upon completion of your Subscription
		purchase through our Website(s) and then, if applicable, each month thereafter during the term of your Subscription. If applicable,
		you hereby authorize us to charge your credit card or other form of payment as described in the foregoing. If at any time during
		your Subscription, your credit card or other method of payment is declined, we may limit your access to the 4D Services and/or
		terminate your Subscription. All fees related to your Subscription are due and payable in U.S Dollars.
	</li>

	<li>
		SUBSCRIPTION AUTOMATIC RENEWAL. Unless you provide us with at least thirty (30) days’ notice prior to the expiration of your
		one-month or one-year Subscription term, your Subscription will be automatically renewed for the same duration as the expiring
		Subscription at the then-current rate for your current Subscription level. If the fees we charge change, we will notify you prior to
		charging you.
	</li>

	<li>
		SUBSCRIPTION TERMINATION AND REFUNDS. You may cancel your Subscription at any time by contacting us at (603) 641-3900, for any or no
		reason, subject to the notice provisions in Section 4 above. If you choose to cancel your Subscription you will still be responsible
		for the monthly subscription fee for the month in which you cancel. However, you will not be responsible for any further
		subscriptions fees unless you re-subscribe to any of the 4D Services. Please note that all student and faculty subscriptions are
		non-refundable.
	</li>

	<li>
		<p>
			4D Technologies may terminate your Subscription, without notice, effective immediately if you breach any of the terms and
			conditions herein. Upon such a termination by 4D Technologies, you will still be responsible for the monthly subscription fee
			for the month in which such termination occurs.
		</p>
		<p>
			4D Technologies may terminate your Subscription, without notice, effective immediately if you breach any of the terms and
			conditions herein. Upon such a termination by 4D Technologies, you will still be responsible for the monthly subscription fee
			for the month in which such termination occurs.
		</p>
	</li>

	<li>
		PRODUCT RETURNS AND REFUNDS. For information about our refund policy for purchases of our Products, please read our
		<a [routerLink]="['/legal']" fragment="return-shipping-policy">Product Refund, Return and Shipping Policy.</a>
	</li>

	<li>
		<p>
			INTELLECTUAL PROPERTY; LIMITED LICENSE. You acknowledge and agree that (excluding your Submissions as defined below) any
			Products made available through our websites, any software (including any mobile applications) that is made available to
			download from the 4D Services (“Software”) and any sample and actual forms, checklists, business documents, our logo and all
			designs, text, graphics, pictures, information, data, software and the selection and arrangement thereof (collectively, the
			“Service Materials”) are the proprietary property of 4D Technologies and/or its licensors and are protected by US and
			International copyright, trademark and/or other intellectual property laws.
		</p>
		<p>
			You will be unable to access or use the 4D Services unless you accept all of the terms and conditions of this TOS. Any
			reproduction or redistribution of the 4D Services not in accordance with these TOS and/or other applicable policies is expressly
			prohibited by law, and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent
			possible.
		</p>
		<p>
			Provided that you indicate your acceptance of these TOS as applicable, you are granted a limited, non-sublicensable,
			non-transferable, revocable license to access and use the 4D Services. This license is subject to these TOS. Any use of the 4D
			Services other than as specifically authorized in these TOS, without our prior written permission, is strictly prohibited and
			will terminate the license granted in these TOS. Such unauthorized use may also violate applicable laws including, but not
			limited to, copyright, trademark and/or other intellectual property laws and applicable communications regulations and statutes.
			Unless explicitly stated herein, nothing in these TOS will be construed as conferring any license to or transfer of any
			intellectual property rights, whether by estoppel, implication or otherwise.
		</p>
	</li>

	<li>
		Provided that you indicate your acceptance of these TOS as applicable, you are granted a limited, non-sublicensable,
		non-transferable, revocable license to access and use the 4D Services. This license is subject to these TOS. Any use of the 4D
		Services other than as specifically authorized in these TOS, without our prior written permission, is strictly prohibited and will
		terminate the license granted in these TOS. Such unauthorized use may also violate applicable laws including, but not limited to,
		copyright, trademark and/or other intellectual property laws and applicable communications regulations and statutes. Unless
		explicitly stated herein, nothing in these TOS will be construed as conferring any license to or transfer of any intellectual
		property rights, whether by estoppel, implication or otherwise.
	</li>

	<li>
		CONTESTS AND PROMOTIONS. Any contests or promotions ("Promotions") accessible through the 4D Services may be governed by its own set
		of official rules, which may include eligibility requirements, such as certain age or geographic area restrictions. By entering or
		participating in such Promotions, you will become subject to those official rules. It is your responsibility to read the applicable
		rules to determine whether or not your participation, registration or entry will be valid or restricted, and to determine the
		sponsor's requirements of you in connection with the applicable Promotion.
	</li>
	<li>
		MEMBER ACCOUNT, PASSWORD, AND SECURITY. Certain sections of, or offerings from, the 4D Services may require you to register for a
		member account. If registration is requested, you agree to provide us with accurate, complete registration information. Your
		registration must be done using your real name and accurate information. Each registration is for your personal use only, unless
		authorized in writing by us. Unless previously authorized in writing by us, we do not permit: (i) any other person using the
		registered sections under your name; or (ii) access through a single name being made available to multiple users on a network. You
		are solely responsible for preventing such unauthorized use.
	</li>

	<li>
		U.S. GOVERNMENT USERS. Any Software downloaded from the 4D Services for or on behalf of the United States of America, its agencies,
		and/or instrumentalities (“U.S. Government”), is provided with Restricted Rights. Use, duplication, or disclosure by the U.S.
		Government is subject to restrictions as set forth in subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer Software
		clause at DFARS 252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial Computer Software – Restricted Rights at 48 CFR
		52.227-19, as applicable. Manufacturer is 4D Technologies, LLC, 360 Route 101, Bldg. #2, Bedford, New Hampshire 03110.
	</li>

	<li>
		SUBMISSION POLICY. You acknowledge that it is your responsibility to ensure that any items that you may upload via the 4D Services
		including without limitation any files, text, schema, data, images or other content (“Submissions”) does not violate the AUP and/or
		any laws, rules, other policies or regulations. We respect your rights and do not wish to censor any of your Submissions. You are
		solely responsible for your use of the 4D Services and for any Submissions that you post via the 4D Services and you agree to use
		the 4D Services at your own risk. We are not obligated to but reserve the right at all times to edit, refuse to post or to remove
		any information, materials and/or Submissions, in whole or in part, that in our sole discretion, violate these TOS,the
		<a [routerLink]="['/legal/']" fragment="acceptable-use-policy">AUP</a> or any other applicable policy, law and/or regulation.
	</li>

	<li>
		USE. You agree to use the 4D Services solely: (i) for their intended purpose; (ii) pursuant to these TOS; (iii) pursuant to the
		terms and conditions of the <a [routerLink]="['/legal/']" fragment="acceptable-use-policy">AUP</a>; and (iv) in accordance with all
		applicable policies, laws and/or regulations.
	</li>

	<li>
		PRIVACY POLICY. Please refer to our <a [routerLink]="['/legal']" fragment="privacy-policy">Privacy Policy</a>
		for information on how we collect, use and disclose personally identifiable information (“Personal Information”) from our users.
	</li>

	<li>
		THIRD PARTY CONTENT. We may occasionally provide links to third party sites which will navigate you away from our website (each a
		“linked site”). These linked sites are not under our control and we are not responsible for the content of any linked site, any link
		contained in a linked site or any changes or updates to such linked sites. Furthermore, we are not responsible for webcasting or any
		other form of transmission received from any linked site. We are providing these links to you only as a convenience, and the
		inclusion of any link does not imply endorsement by us of the applicable linked site.
	</li>

	<li>
		<p>
			WARRANTY DISCLAIMER; LIMITATION OF LIABILITY. UNLESS OTHERWISE EXPRESSLY SET FORTH IN WRITING THE PRODUCTS AND 4D SERVICES
			(INCLUDING WITHOUT LIMITATION OUR WEBSITE, SERVICES, SOFTWARE, SERVICE MATERIALS AND/OR ANY OTHER CONTENT INCLUDED IN OR
			OTHERWISE MADE AVAILABLE TO YOU THROUGH THE 4D SERVICES) ARE PROVIDED TO YOU “AS IS” WITHOUT ANY REPRESENTATIONS OR WARRANTIES
			OR CONDITIONS OF ANY KIND. WE DO NOT WARRANT THAT ANY 4D SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE MAKE NO
			REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE AVAILABILITY, RELIABILITY OR SECURITY OF ANY OF THE PRODUCTS AND/OR 4D
			SERVICES. UNLESS OTHERWISE EXPRESSLY SET FORTH IN WRITING, WE DISCLAIM ANY AND ALL OTHER WARRANTIES AND REPRESENTATIONS (EXPRESS
			OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO THE PRODUCTS AND 4D SERVICES WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY
			REASON OF CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE INCLUDING: (I) ANY AND ALL WARRANTIES OF
			MERCHANTABILITY; (II) ANY AND ALL WARRANTIES OF FITNESS OR SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT KNOWN, HAVE REASON TO
			KNOW, HAVE BEEN ADVISED, OR ARE OTHERWISE AWARE OF ANY SUCH PURPOSE); AND (III) ANY AND ALL WARRANTIES OF NONINFRINGEMENT OR
			CONDITION OF TITLE.
		</p>
		<p>
			THE PRODUCTS AND 4D SERVICES ARE INTENDED FOR TRAINING PURPOSES ONLY. AS SUCH THE PRODUCTS AND 4D SERVICES ARE NOT
			FAULT-TOLERANT AND ARE NOT DESIGNED, MANUFACTURED OR INTENDED FOR USE IN HAZARDOUS APPLICATIONS REQUIRING FAIL-SAFE PERFORMANCE,
			SUCH AS IN THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION OR AIRCRAFT COMMUNICATION SYSTEMS, MASS TRANSIT, AIR TRAFFIC
			CONTROL, DIRECT LIFE SUPPORT MACHINES, WEAPONS SYSTEMS OR IN ANY OTHER APPLICATION IN WHICH THE FAILURE OF THE COMPONENT SYSTEMS
			COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE ("HIGH RISK ACTIVITIES"). ACCORDINGLY,
			WE DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY OF FITNESS FOR HIGH RISK ACTIVITIES. YOU AGREE THAT 4D TECHNOLOGIES SHALL NOT BE
			LIABLE FOR ANY CLAIMS OR DAMAGES ARISING FROM OR RELATED TO THE USE OF THE PRODUCTS AND/OR 4D SERVICES IN SUCH APPLICATIONS.
		</p>
		<p>
			TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR LOSS OF REVENUE, LOSS OF
			PROFITS OR ANY SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION
			WITH THE PRODUCTS AND/OR 4D SERVICES, YOUR CONDUCT OR THE CONDUCT OF ANY OTHER USERS, INCLUDING, BUT NOT LIMITED TO, BODILY
			INJURY, EMOTIONAL DISTRESS, ANY OTHER DAMAGES AS A RESULT OF CONTACT WITH OTHER USERS AND/OR ANY DAMAGES CAUSED BY OR RESULTING
			FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM US, VIA THE PRODUCTS AND/OR 4D SERVICES OR THAT MAY RESULT FROM MISTAKES,
			OMISSIONS, INTERRUPTIONS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR UNAUTHORIZED ACCESS TO THE PRODUCTS AND/OR 4D SERVICES,
			REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
			SUCH DAMAGES OR ARE AWARE OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR TOTAL CUMULATIVE LIABILITY EXCEED THE
			AGGREGATE OF THE NET AMOUNTS RECEIVED BY US FROM YOU, IF ANY, FOR THE ACCESS TO AND USE OF THE PRODUCTS AND/OR 4D SERVICES
			PROVIDED HEREUNDER IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEEDING THE EVENT(S) GIVING RISE TO SUCH LIABILITY.
		</p>
	</li>

	<li>
		INDEMNIFICATION. You agree, at your sole expense, to defend, indemnify and hold 4D Technologies, its officers, directors, managers,
		members, employees and agents, licensors, independent contractors, service providers and consultants, and their respective officers,
		directors, employees and agents, harmless from and against any and all actual or threatened suits, actions, proceedings, at law or
		in equity, claims, damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses,
		including, but not limited to, reasonable attorney fees, costs, penalties, interest and disbursements caused by, arising out of,
		resulting from, attributable to or in any way incidental to: (i) your use of the Products and/or 4D Services; (ii) your conduct;
		(iii) your violation of these TOS or any other 4D Technologies policy or applicable law and/or regulation; (iv) your violation of
		the rights of any third party; or (v) any of your Submissions.
	</li>

	<li>
		CONFLICT RESOLUTION. You and 4D Technologies (each individually a “Party” and collectively the “Parties”) agree that any dispute
		relating to the Products and/or 4D Services will be resolved solely by the means set forth in this section. In the event that any
		such dispute arises, you agree to first contact us directly. The Parties agree to make a good faith effort to resolve the dispute.
		If the matter remains unresolved for sixty (60) days after your initial contact with us regarding the dispute, the matter will be
		submitted to binding arbitration under the rules of the JAMS. Any such arbitration will be conducted in Concord, New Hampshire -
		USA. The arbitrator(s) involved in settling any such dispute will have authority to grant specific performance and to allocate the
		costs of arbitration between the Parties as the arbitrator(s) deems equitable. Any such judgments rendered by the arbitrator(s) may
		be entered in any court having jurisdiction or application may be made to such court for judicial acceptance of any award and an
		order of enforcement, as the case may be.
	</li>
	<li>
		GOVERNING LAW; VENUE. Any dispute arising under or in connection with the subject matter contemplated herein or related to such
		subject matter shall be governed by, and construed in accordance with, the substantive laws of the State of New Hampshire - USA,
		without regard to its conflict of laws principles. You agree that any claim or dispute you may have against 4D Technologies must be
		resolved by a court located in Hillsboro New Hampshire, except as otherwise agreed to or as described in the Conflict Resolution
		paragraph above. You agree to submit to the personal jurisdiction of such courts for the purpose of litigating all such claims or
		disputes. In addition to any other relief awarded, the prevailing party in any action arising out of your use of or access to the 4D
		Services shall be entitled to its reasonable attorneys’ fees and costs.
	</li>

	<li>
		WAIVER. 4D Technologies’ failure to strictly enforce any provision of these TOS and/or any other applicable policy will not
		constitute a waiver of any such provision.
	</li>

	<li>
		ENTIRE AGREEMENT. These TOS, any applicable Enterprise Subscription Agreement (as defined below) and any updates thereto, will
		constitute the entire agreement between you and 4D Technologies concerning the 4D Services and supersedes all prior understandings,
		agreements and documentation relating to such subject matter.
	</li>

	<li>
		SEVERABILITY. If any provision of these TOS is or becomes or is deemed invalid, illegal or unenforceable under the applicable laws
		or regulations of any jurisdiction, such provision will be deemed amended to conform to such laws or regulations without materially
		altering the intention of the parties hereto, or it will be stricken and the remainder of these TOS will remain in full force and
		effect.
	</li>
	<li>
		<p>
			23. CLAIMS OF COPYRIGHT INFRINGEMENT. If you believe that anything made available via the 4D Services infringes upon any
			copyright which you own or control, you may file a notification of such infringement with our Designated Agent as set forth
			below.
		</p>
		<p>
			For your complaint to be valid under the DMCA, you must provide the following information when providing notice of the claimed
			copyright infringement:
		</p>
		<ol class="tnc-list">
			<li>a. A physical or electronic signature of a person authorized to act on behalf of the copyright owner;</li>
			<li>b. Identification of the copyrighted work claimed to have been infringed;</li>
			<li>
				c. Identification of the material that is claimed to be infringing or to be the subject of the infringing activity and that
				is to be removed or access to which is to be disabled as well as information reasonably sufficient to permit 4D Technologies
				to locate the material;
			</li>
			<li>
				d. Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address,
				telephone number, and, if available, an electronic mail address;
			</li>
			<li>
				e. A statement that the complaining party has a good faith belief that use of the material in the manner complained of is
				not authorized by the copyright owner, its agent, or law; and
			</li>
			<li>
				f. A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining
				party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
			</li>
		</ol>
		<p>
			For more details on the information required for valid notification, see 17 U.S.C. 512(c) (3). You should be aware that, under
			the DCMA, claimants who make misrepresentations concerning copyright infringement may be liable for damages incurred as a result
			of the removal or blocking of the material, court costs, and attorneys’ fees.
		</p>
		<p>
			If a notice of copyright infringement has been filed against you, you may file a counter notification with 4D Technologies’
			designated agent at the address listed below. Such counter notification must contain the following information:
		</p>
		<ol class="tnc-list">
			<li>a. A physical or electronic signature;</li>
			<li>
				b. Identification of the material that has been removed or to which access has been disabled and the location at which the
				material appeared before it was removed or access to it was disabled;
			</li>
			<li>
				c. A statement under penalty of perjury that the Member has a good faith belief that the material was removed or disabled as
				a result of mistake or misidentification; and
			</li>
			<li>
				d. Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the federal district
				court for the federal district in which you are located, and that you will accept service of process from the complainant.
			</li>
		</ol>
		<p>
			If 4D Technologies receives a valid counter notification, the DMCA provides that the removed material will be restored, or
			access re-enabled.
		</p>
		<p>Contact for DMCA complaints:</p>
		<p>
			Email –
			<a href="mailto:registrar@cadlearning.com">registrar@cadlearning.com</a>
		</p>
		<p>Phone – (603) 641-3900</p>
	</li>
	<li>
		INCIDENT REPORTING. Report violations of these TOS, spam, or other similar issues or problems at: registrar@cadlearning.com; or by
		mail at 4D Technologies, LLC, 360 Route 101, Building 2, Bedford, New Hampshire 03110.
	</li>
	<li>
		<p>
			TRADEMARK AND COPYRIGHT NOTICE. CADLearning® is a registered trademark owned by 4D Technologies. You agree that all 4D
			Technologies trademarks, trade names, service marks and other 4D Technologies logos and brand features, and product and service
			names are trademarks and the property of 4D Technologies (the “4D Marks”). You agree not to display or use in any manner the 4D
			Marks without our prior written permission.
		</p>
		<p>
			All other trademarks mentioned in conjunction with the 4D Services (“Third Party Marks”) are owned by their respective owners
			and are protected by United States and international law. You agree not to display or use in any manner the Third Party Marks
			without express permission from the respective owner.
		</p>
		<p>
			Copyright © 2004 – 2019 4D Technologies, LLC, 360 Route 101, Building 2, Bedford, New Hampshire 03110 U.S.A. All rights
			reserved.
		</p>
		<p>
			Adobe ® AIR® Copyright © 2007 – 2019. Adobe Systems Incorporated. All rights reserved. Adobe, Adobe AIR, and AIR are either
			trademarks or registered trademarks in the United States and/or other countries.
		</p>
	</li>
	<li>
		ENTERPRISE CUSTOMERS. If you are not an individual customer or end user, but are purchasing access to the 4D Services for multiple
		employees, students or customers (collectively, “Students”), then you are required to enter into a separate Enterprise Subscription
		Agreement. Use of the 4D Services by you or any of your Students constitutes a representation that you have entered into an
		appropriate Enterprise Subscription Agreement. Except to the extent that the terms of your Enterprise Subscription Agreement
		conflicts with the provisions of these TOS, these TOS will also apply to your access to the 4D Services under your Enterprise
		Subscription Agreement. With the exception of this Section 16, these TOS and the AUP apply in all cases to your Students.
	</li>
</ol>
<p>THESE TERMS OF USE WERE LAST UPDATED: December 31, 2019</p>
